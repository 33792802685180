import * as React from 'react'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link, useLocation } from 'react-router-dom'

import GlobeIcon from '../components/Icons/GlobeIcon'
import { Container } from './Container'

const TopNavigation = () => {
	const { t } = useTranslation()

	const biggerThan700 = useMediaQuery({
		minWidth: 700,
	})

	const [open, setOpen] = useState(false)
	const [accessiblyHidden, setAccessiblyHidden] = useState(!open)

	const location = useLocation()

	useEffect(() => {
		setOpen(false)
	}, [location])

	useEffect(() => {
		if (open) {
			setAccessiblyHidden(false)
		} else {
			const timeout = setTimeout(() => {
				setAccessiblyHidden(true)
			}, 300)
			return () => clearTimeout(timeout)
		}
	}, [open])

	return (
		<header>
			<div className="top-bar">
				<Link to="/">
					<img
						className="nav-logo"
						src="/mymoney-logo.svg"
						alt="mymoney logo"
					/>
				</Link>
				{biggerThan700 ? (
					<nav>
						<Link to="/pay">{t('top_navigation_pay_link')}</Link>
						<Link to="/yhteytta">{t('top_navigation_contact_link')}</Link>
						<Link to="/tiedot">{t('footer_about_link')}</Link>
						<ChangeLanguage />
					</nav>
				) : (
					<>
						<button
							aria-controls="main-menu"
							aria-expanded={open}
							className="hamburger"
							onClick={() => {
								setOpen(!open)
							}}
						>
							<span id="hamburger-toggle">
								<span></span>
								<span></span>
								<span></span>
								<span></span>
							</span>
						</button>
						<Container
							id="main-menu"
							className={`
								hamburger-menu-content ${accessiblyHidden ? 'accessibly-hidden' : ''}
							`.trim()}
						>
							<ul>
								<li>
									<Link to="/pay">{t('top_navigation_pay_link')}</Link>
								</li>
								<li>
									<Link to="/yhteytta">{t('top_navigation_contact_link')}</Link>
								</li>
								<li>
									<Link to="/tiedot">{t('footer_about_link')}</Link>
								</li>
								<li>
									<ChangeLanguage />
								</li>
							</ul>
						</Container>
					</>
				)}
			</div>
		</header>
	)
}

export default TopNavigation

export const ChangeLanguage = () => {
	const { i18n } = useTranslation()

	return (
		<div className="lang-choice">
			<GlobeIcon />
			<button
				onClick={() => i18n.changeLanguage('fi')}
				className={i18n.language === 'fi' ? 'selected' : undefined}
			>
				fi
			</button>
			<span> / </span>
			<button
				onClick={() => i18n.changeLanguage('sv')}
				className={i18n.language === 'sv' ? 'selected' : undefined}
			>
				sv
			</button>
		</div>
	)
}
