import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '../components/Container'
import Checkmark from '../images/icons/5-pages-landing-page-area-feature-icon.svg'
import CarLogo from '../images/icons/5-pages-landing-page-expert-area-header-icons-car.svg'
import CoOpLogo from '../images/icons/5-pages-landing-page-expert-area-header-icons-co-op.svg'
import HeartLogo from '../images/icons/5-pages-landing-page-expert-area-header-icons-customers.svg'
import TechnologyLogo from '../images/icons/5-pages-landing-page-expert-area-header-icons-technology.svg'
import ApplicationLogo from '../images/icons/5-pages-landing-page-icon-title-message-component-icons-application.svg'
import PayoutLogo from '../images/icons/5-pages-landing-page-icon-title-message-component-icons-payout.svg'
import ScreenRocketLogo from '../images/icons/5-pages-landing-page-icon-title-message-component-icons-quick.svg'
import ThumbDown from '../images/icons/icon-thumbdown@3x.png'
import ThumbUp from '../images/icons/icon-thumbup@3x.png'
import CheckmarkCircle from '../images/icons/landing-page-circular-checkmark-small.svg'
import PayLogoNegative from '../images/my-money-pay-negative.svg'
import heroImage from '../images/pages/pay/hero-image.jpg'
import heroVideo from '../images/pages/pay/hero-video.webm'
import ProductPitchImage from '../images/product-pitch.jpg'

const Pay = () => {
	const [formName, setFormName] = useState('')
	const [formPhoneNumber, setFormPhoneNumber] = useState('')
	const [formEmail, setFormEmail] = useState('')
	const [formOrgNumber, setFormOrgNumber] = useState('')
	const [formSubmitted, setFormSubmitted] = useState(false)

	const handleSubmit = () => {
		setFormSubmitted(true)
	}

	const { t } = useTranslation()

	return (
		<div className="pay-page">
			<div id="pay-page-hero">
				<img src={heroImage} alt="" />
				<video
					src={heroVideo}
					autoPlay={true}
					muted={true}
					loop={true}
					preload="auto"
				/>
				<Container className="video-text-container">
					<img height={44} src={PayLogoNegative} alt="MyMoney Pay logo" />
					<h2>
						{t('pay_hero_heading1')}
						<br />
						{t('pay_hero_heading2')}
					</h2>
					<div className="hero-buttons-div">
						<Link to="/pay#more-about-pay" className="button button--primary">
							{t('pay_hero_more_about_pay_button')}
						</Link>
						<Link
							to="https://pay.mymoney.fi/"
							className="button button--primary button--outline"
						>
							{t('pay_hero_login_button')}
						</Link>
					</div>
				</Container>
			</div>
			<Container id="more-about-pay" className="py-20">
				<div>
					<h2>{t('pay_more_about_pay_heading')}</h2>
					<p>{t('pay_more_about_pay_text')}</p>
					<ul>
						<li>
							<img height={16} src={CheckmarkCircle} alt="Green checkmark" />
							<span className="bullets">{t('pay_more_about_pay_bullet1')}</span>
						</li>
						<li>
							<img height={16} src={CheckmarkCircle} alt="Green checkmark" />
							<span className="bullets">{t('pay_more_about_pay_bullet2')}</span>
						</li>
						<li>
							<img height={16} src={CheckmarkCircle} alt="Green checkmark" />
							<span className="bullets">{t('pay_more_about_pay_bullet3')}</span>
						</li>
					</ul>
					<div className="hero-buttons-div">
						<Link to="/pay#apply-pay" className="button button--primary">
							{t('pay_more_about_pay_button_text')}
						</Link>
					</div>
				</div>
				<div className="pitch-image">
					<img
						src={ProductPitchImage}
						alt="Phone and tablet with MyMoney pay app"
					/>
				</div>
			</Container>
			<div id="mymoney-unique" className="container py-20">
				<div className="unique-top-section">
					<h2>{t('pay_mymoney_unique_heading')}</h2>
					<p className="max-w-prose mx-auto">{t('pay_mymoney_unique_text')}</p>
				</div>
				<div className="thumbs-section">
					<div>
						<img
							width={52}
							height={52}
							src={ThumbDown}
							alt="Thumbs down emoji"
						/>
						<h3>{t('pay_thumbs_down_heading')}</h3>
						<p>{t('pay_thumbs_down_paragraph1')}</p>
						<p>{t('pay_thumbs_down_paragraph2')}</p>
						<p>{t('pay_thumbs_down_paragraph3')}</p>
					</div>
					<div className="pay-border">
						<img width={52} height={52} src={ThumbUp} alt="Thumbs up emoji" />
						<h3>{t('pay_thumbs_up_heading')}</h3>
						<p>{t('pay_thumbs_up_paragraph1')}</p>
						<p>{t('pay_thumbs_up_paragraph2')}</p>
						<p>{t('pay_thumbs_up_paragraph3')}</p>
					</div>
				</div>
				<div className="bottom-section">
					<div>
						<img src={ApplicationLogo} alt="Application logo" />
						<h3>{t('pay_simple_application_heading')}</h3>
						<p>{t('pay_simple_application_text')}</p>
					</div>
					<div>
						<img src={ScreenRocketLogo} alt="Screen rocket logo" />
						<h3>{t('pay_fast_credit_heading')}</h3>
						<p>{t('pay_fast_credit_text')}</p>
					</div>
					<div>
						<img src={PayoutLogo} alt="Payout logo" />
						<h3>{t('pay_fast_payment_heading')}</h3>
						<p>{t('pay_fast_payment_text')}</p>
					</div>
				</div>
			</div>
			<div id="apply-pay">
				<div className="fields-header max-w-prose mx-auto">
					<h2>{t('pay_apply_form_heading')}</h2>
					<p>
						{t('pay_apply_form_text1')}{' '}
						<Link to="tel:+358 9 31549402">09 31549402</Link>{' '}
						{t('pay_apply_form_text2')} <br />
						{t('pay_apply_form_text3')}
					</p>
				</div>
				{!formSubmitted ? (
					<form action="" onSubmit={handleSubmit}>
						<Container className="fields-container">
							<div>
								<label className="input-label-pay" htmlFor="formName">
									{t('pay_form_name_label')}
								</label>
								<input
									id="formName"
									value={formName}
									onChange={e => setFormName(e.target.value)}
									type="text"
									placeholder={t('pay_form_name_placeholder')}
								/>
							</div>
							<div>
								<label className="input-label-pay" htmlFor="formPhone">
									{t('pay_form_phone_label')}
								</label>
								<input
									id="formPhone"
									value={formPhoneNumber}
									onChange={e => setFormPhoneNumber(e.target.value)}
									type="text"
									placeholder={t('pay_form_phone_placeholder')}
								/>
							</div>
							<div>
								<label className="input-label-pay" htmlFor="formEmail">
									{t('pay_form_email_label')}
								</label>
								<input
									id="formEmail"
									value={formEmail}
									onChange={e => setFormEmail(e.target.value)}
									type="text"
									placeholder={t('pay_form_email_placeholder')}
								/>
							</div>
							<div>
								<label className="input-label-pay" htmlFor="formOrgNumber">
									{t('pay_form_company_id_label')}
								</label>
								<input
									id="formOrgNumber"
									value={formOrgNumber}
									onChange={e => setFormOrgNumber(e.target.value)}
									type="text"
									placeholder={t('pay_form_company_id_placeholder')}
								/>
							</div>
							<a
								href={`mailto:info@mymoney.fi?subject=Intresseanmälan%20Pay%20Finland&body=Haluan%20rekisteröidä%20kiinnostukseni%20MyMoney%20Pay.%0D%0ANimi:%20${formName}%0D%0APuhelin:%20${formPhoneNumber}%0D%0ASähköposti:${formEmail}%0D%0AY-tunnus:%20${formOrgNumber}`}
								className="button button--primary"
							>
								{t('pay_form_button_text')}
							</a>
						</Container>
					</form>
				) : (
					<p className="form-submit-success">{t('pay_form_submitted_text')}</p>
				)}
			</div>
			<div id="suitable-for-my-business">
				<h2>{t('pay_suitable_for_my_business_heading')}</h2>
				<p className="max-w-prose mx-auto">
					{t('pay_suitable_for_my_business_text')}
				</p>
				<div className="mymoney-car">
					<div className="mymoney-car-top">
						<img src={CarLogo} alt="Car icon" />
						<h4>{t('pay_car_heading')}</h4>
					</div>
					<div>
						<ul>
							{(console.log(t('pay_car_bullet')), t('pay_car_bullet')).map(
								(bullet, index) => (
									<li key={index}>
										<img src={Checkmark} alt="Green checkmark" />
										<span>{bullet}</span>
									</li>
								),
							)}
						</ul>
					</div>
				</div>
			</div>
			<div id="what-do-i-get" className="container">
				<h2>{t('pay_what_do_i_get_heading')}</h2>
				<div className="what-do-i-get-items">
					<div>
						<img src={TechnologyLogo} alt="Logo visualizing technology" />
						<h3>{t('pay_technology_heading')}</h3>
						<p>{t('pay_technology_text')}</p>
					</div>
					<div>
						<img src={CoOpLogo} alt="Logo of handshake" />
						<h3>{t('pay_co_op_heading')}</h3>
						<p>{t('pay_co_op_text')}</p>
					</div>
					<div>
						<img src={HeartLogo} alt="Logo of a heart" />
						<h3>{t('pay_customers_heading')}</h3>
						<p>{t('pay_customers_text')}</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Pay
