import i18n from 'i18next'
import posthog from 'posthog-js'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { initReactI18next } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import App from './App'
import About from './pages/About'
import Contact from './pages/Contact'
import Home from './pages/Home'
import ComplaintHandling from './pages/legal/ComplaintHandling'
import Cookies from './pages/legal/Cookies'
import PrivacyPolicy from './pages/legal/PrivacyPolicy'
import TermsAndConditions from './pages/legal/TermsAndConditions'
import NotFound from './pages/NotFound'
import Pay from './pages/Pay'
import reportWebVitals from './reportWebVitals'
import resources from './translations/translations'
import { CookieConsentProvider } from './utils/CookieConsentContext'

posthog.init('phc_6DlBrMh0xWAX3e9NCGamjQRgYNLvjjg2cH61Axm7qtz', {
	api_host: 'https://eu.posthog.com',
})

i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		resources,
		lng: 'fi',
		fallbackLng: 'sv',
		interpolation: {
			escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
		},
		debug: false,
		returnObjects: true,
	})

const router = createBrowserRouter(
	[
		{
			Component: App,
			children: [
				{
					index: true,
					Component: Home,
				},
				{
					path: 'pay',
					Component: Pay,
				},
				{
					path: 'yhteytta',
					Component: Contact,
				},
				{
					path: 'kayttoehdot',
					Component: TermsAndConditions,
				},
				{
					path: 'evasteet',
					Component: Cookies,
				},
				{
					path: 'valitukset',
					Component: ComplaintHandling,
				},
				{
					path: 'tietosuojaseloste',
					Component: PrivacyPolicy,
				},
				{
					path: 'tiedot',
					Component: About,
				},
				{
					path: '*',
					Component: NotFound,
				},
			],
		},
	],
	{
		future: {
			v7_normalizeFormMethod: true,
			v7_prependBasename: true,
		},
	},
)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<CookieConsentProvider>
			<RouterProvider router={router} />
		</CookieConsentProvider>
	</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
