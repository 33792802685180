const StarIcon = () => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			x="0px"
			y="0px"
			height="30px"
			viewBox="0 0 92 92"
			enableBackground="new 0 0 92 92"
			fill="currentColor"
		>
			<path
				id="XMLID_310_"
				d="M70.7,88c-0.8,0-1.5-0.2-2.2-0.6L46,72.7L23.5,87.4c-1.4,0.9-3.2,0.8-4.5-0.1c-1.3-1-1.9-2.7-1.5-4.3
	l7.1-25L3.4,40.1c-1.2-1-1.7-2.7-1.2-4.3s1.9-2.6,3.5-2.8L31,31.2L42.4,6.3C43,4.9,44.4,4,46,4s3,0.9,3.6,2.3L61,31.2L86.3,33
	c1.6,0.1,3,1.2,3.5,2.8c0.5,1.5,0,3.2-1.2,4.3L67.5,57.9l7.1,25c0.5,1.6-0.1,3.3-1.5,4.3C72.4,87.7,71.6,88,70.7,88z M46,64
	c0.8,0,1.5,0.2,2.2,0.6L64,74.9l-4.9-17.3c-0.4-1.5,0.1-3.1,1.3-4.1l15.5-13.1L58.1,39c-1.5-0.1-2.7-1-3.4-2.3L46,17.6l-8.7,19.1
	c-0.6,1.3-1.9,2.2-3.4,2.3l-17.8,1.3l15.5,13.1c1.2,1,1.7,2.6,1.3,4.1L28,74.9l15.8-10.2C44.5,64.2,45.2,64,46,64z"
			/>
		</svg>
	)
}

export default StarIcon
