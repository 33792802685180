import { useCookieConsent } from '../utils/CookieConsentContext'

const BottomCookieButton = () => {
	const { showConsentDialog } = useCookieConsent()

	return (
		<button
			className="button button--sm button--primary button--fixed-cookie-consent"
			onClick={showConsentDialog}
		>
			Cookies
		</button>
	)
}

export default BottomCookieButton
