import './styles/globals.css'

import i18n from 'i18next'
import posthog from 'posthog-js'
import * as React from 'react'
import { useEffect } from 'react'
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom'

import BottomCookieButton from './components/BottomCookieButton'
import Footer from './components/Footer'
import TopNavigation from './components/TopNavigation'

const App = () => {
	useEffect(() => {
		i18n.on('languageChanged', lng => {
			document.documentElement.setAttribute('lang', lng)
		})
	}, [])

	return (
		<>
			<TopNavigation />

			<main>
				<Outlet />
			</main>

			<Analytics />
			<ScrollRestoration />

			<Footer />
			<BottomCookieButton />
		</>
	)
}

const Analytics = () => {
	let location = useLocation()

	useEffect(() => {
		posthog.capture('$pageview')
	}, [location])

	return null
}

export default App
