import 'vanilla-cookieconsent/dist/cookieconsent.css'

import { posthog } from 'posthog-js'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as cc from 'vanilla-cookieconsent'

const cookieConsent = createContext({
	_instance: null,
	showConsentDialog: () => {},
	changeLanguage: () => {},
	hasValidConsent: false,
})

const toggleAnalytics = () => {
	if (cc.acceptedCategory('analytics')) {
		posthog.opt_in_capturing()
	} else {
		posthog.opt_out_capturing()
	}
}

export const CookieConsentProvider = ({ children }) => {
	const [hasValidConsent, setHasValidConsent] = useState(false)

	useEffect(() => {
		toggleAnalytics()
		const onConsent = () => {
			toggleAnalytics()
			setHasValidConsent(cc.validConsent())
		}

		cc.run({
			onFirstConsent: onConsent,
			onChange: onConsent,

			revision: '1',

			categories: {
				necessary: {
					enabled: true,
					readOnly: true,
				},
				analytics: {
					enabled: false,
					readOnly: false,
					autoClear: {
						cookies: [{ name: /^ph_/ }],
					},
				},
			},
			guiOptions: {
				consentModal: {
					layout: 'cloud',
					position: 'bottom center',
					equalWeightButtons: true,
				},
				preferencesModal: {
					layout: 'box',
					// position: 'left right',
					equalWeightButtons: true,
				},
			},

			language: {
				default: 'fi',
				autoDetect: 'document',
				translations: {
					fi: {
						consentModal: {
							title: 'Evästeasetukset',
							description: `
								Parantaaksemme käyttökokemustasi verkkosivustollamme käytämme välttämättömiä evästeitä. 
								Käytämme analyysievästeitä verkkosivuston parantamiseen, sekä evästeiden asettamiseen verkkosivuston toiminnallisuuden sekä räätälöintiä varten. 
								Voit muuttaa tai peruuttaa evästeen asetuksiasi milloin tahansa. Lue lisää evästeiden käytöstä <a href='/evasteet'>evästekäytännöstämme</a>. 
							`,
							revisionMessage: 'site.cookies.revisionMessage',
							acceptAllBtn: 'OK',
							acceptNecessaryBtn: 'Hyväksy vallinaiset',
							showPreferencesBtn: 'Muokkaa',
						},
						preferencesModal: {
							title: 'Evästeasetukset',
							acceptAllBtn: 'OK',
							acceptNecessaryBtn: 'Hyväksy vallinaiset',
							savePreferencesBtn: 'Tallenna asetukset',
							closeIconLabel: 'Stäng',
							sections: [
								{
									title: '',
									description: `
                  Käytämme evästeitä varmistaaksemme verkkosivuston perustoiminnallisuuden, sekä parantaaksemme verkkokokemustasi. 
                  Voit valita evästekategoriasta, jos haluat käyttää evästeitä tai poistaa niitä käytöstä. Jos haluat lisätietoja evästeistä sekä muista arkaluonteisista tiedoista, lue meidän
                  <a href='/evasteet' class='cc-link'>evästekäytäntömme</a>.
                  `,
								},
								{
									title: 'Tarvittavat evästeet',
									description:
										'Nämä evästeet ovat tarpeellisia sivuston toiminnan kannalta sekä että palvelumme toimivat parhaimmalla tavalla.',
									linkedCategory: 'necessary',
									cookieTable: {
										headers: {
											name: 'Nimi',
											domain: 'Toimittaja',
											description: 'Laillinen kuvaus',
											expiration: 'Lakkauttaa',
										},
										body: [],
									},
								},
								{
									title: 'Analyysi',
									description:
										'Kerää tietoja verkkosivun käyttäjistä, tunnistamatta yksittäisiä kävijöitä henkilökohtaisesti Googlessa. Tämä mahdollistaa vierailijoiden erottamisen toisistaan.',
									linkedCategory: 'analytics',
									cookieTable: {
										headers: {
											name: 'Nimi',
											domain: 'Toimittaja',
											description: 'Laillinen kuvaus',
											expiration: 'Lakkauttaa',
										},
										body: [
											{
												name: 'ph_phc_*',
												domain: 'Posthog',
												description:
													'Kerää nimettömiä tietoja siitä, miten kävijämme käyttävät verkkosivustoa. Eväste sisältää satunnaisesti luodun tunnisteen, jota käytetään selaimen tunnistamiseen, kun kävijä käyttää sivua. Eväste ei sisällä mitään henkilökohtaisia tietoja, ja sitä käytetään vain verkkoanalyysiin.',
												expiration: '1 vuosi',
											},
										],
									},
								},
								{
									title: 'Lisätietoja',
									description: `
                  Jos sinulla on kysymyksiä evästekäytännöstämme sekä valinnoistasi, ota <a class='cc-link' href='/yhteytta'>meihin yhteyttä</a>.
                  `,
								},
							],
						},
					},
					sv: {
						consentModal: {
							title: 'Inställningar för cookies',
							description: `
                För att förbättra din upplevelse av vår webbplats använder vi nödvändiga cookies. 
                Vi använder också analyscookies för att förbättra webbplatsen samt cookies för inställningar för webbplatsens funktionalitet och anpassning. 
                Du kan när som helst ändra eller återkalla ditt samtycke. 
                Läs mer om hur vi använder cookies i vår <a href='/evasteet'>cookiepolicy</a>. 
              `,
							revisionMessage: 'site.cookies.revisionMessage',
							acceptAllBtn: 'Acceptera alla',
							acceptNecessaryBtn: 'Acceptera nödvändiga',
							showPreferencesBtn: 'Anpassa',
						},
						preferencesModal: {
							title: 'Inställningar för cookies',
							acceptAllBtn: 'Acceptera alla',
							acceptNecessaryBtn: 'Acceptera nödvändiga',
							savePreferencesBtn: 'Spara inställningar',
							closeIconLabel: 'Stäng',
							sections: [
								{
									title: '',
									description: `
                    Vi använder cookies för att säkerställa webbplatsens grundläggande funktioner och för att förbättra din onlineupplevelse. 
                    Du kan välja för varje kategori att välja in/ut när du vill. 
                    För mer information om cookies och annan känslig data, läs hela <a href='/evasteet' class='cc-link'>cookiepolicy</a>.
                  `,
								},
								{
									title: 'Nödvändiga cookies',
									description:
										'Används för grundläggande webbplatsfunktionalitet och för att säkerställa att våra tjänster fungerar på bästa sätt.',
									linkedCategory: 'necessary',
									cookieTable: {
										headers: {
											name: 'Namn',
											domain: 'Leverantör',
											description: 'Juridisk Beskrivning',
											expiration: 'Upphör',
										},
										body: [],
									},
								},
								{
									title: 'Analys',
									description:
										'Samlar in information om webbplatsanvändningsstatistik utan att personligen identifiera enskilda besökare. Gör det möjligt att särskilja en besökare från en annan.',
									linkedCategory: 'analytics',
									cookieTable: {
										headers: {
											name: 'Namn',
											domain: 'Leverantör',
											description: 'Juridisk Beskrivning',
											expiration: 'Upphör',
										},
										body: [
											{
												name: 'ph_phc_*',
												domain: 'Posthog',
												description:
													'Samlar in anonymiserad information om hur våra besökare använder webbplatsen. Cookien innehåller ett slumpmässigt genererat ID som används för att känna igen webbläsaren när en besökare läser en sida. Cookien innehåller ingen personlig information och används endast för webbanalys.',
												expiration: '1 år',
											},
										],
									},
								},
								{
									title: 'Mer information',
									description: `
                    För alla frågor angående vår policy för cookies och dina val, vänligen <a class='cc-link' href='/yhteytta'>kontakta oss</a>.
                  `,
								},
							],
						},
					},
				},
			},
		})

		setHasValidConsent(cc.validConsent())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const { i18n } = useTranslation()

	useEffect(() => {
		const callback = lng => {
			cc.setLanguage(lng)
		}
		i18n.on('languageChanged', callback)
		return () => i18n.off('languageChanged', callback)
	}, [])

	return (
		<cookieConsent.Provider
			value={useMemo(
				() => ({
					_instance: cc,
					showConsentDialog: () => cc.show(true),
					hasValidConsent,
				}),
				[hasValidConsent],
			)}
		>
			{children}
		</cookieConsent.Provider>
	)
}

export const useCookieConsent = () => useContext(cookieConsent)
