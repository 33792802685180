import { useTranslation } from 'react-i18next'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import { Container } from '../components/Container'
import HeartIcon from '../components/Icons/HeartIcon'
import RocketIcon from '../components/Icons/RocketIcon'
import StarIcon from '../components/Icons/StarIcon'

const Home = () => {
	const biggerThan700 = useMediaQuery({
		minWidth: 700,
	})
	const { t } = useTranslation()
	return (
		<div className="home-page">
			<div className="hero">
				<Container className="self-center">
					<h1 className="line-center">
						<span className="font-semibold">MYMONEY</span>
						<br />
						{' ' + t('home_hero_heading')}
					</h1>
					<p className="mx-auto max-w-prose">
						<i>{t('home_hero_text')}</i>
					</p>
					<p>
						<Link to="#usp" className="button button--primary">
							{t('home_read_more_button')} →
						</Link>
					</p>
				</Container>
			</div>
			<div id="usp">
				<Container>
					<div className="usp-container">
						<div>
							<h3>
								<RocketIcon />
								{t('home_usp_safe_heading')}
							</h3>
							<p>{t('home_usp_safe_text')}</p>
						</div>
						<div>
							<h3>
								<StarIcon />
								{t('home_usp_simple_heading')}
							</h3>
							<p>{t('home_usp_simple_text')}</p>
						</div>
						<div>
							<h3>
								<HeartIcon />
								{t('home_usp_personal_heading')}
							</h3>
							<p>{t('home_usp_personal_text')}</p>
						</div>
					</div>
				</Container>
			</div>

			{/*
			// Hidden since the finish website doesn't have the rights to use TrustPilot
			<Testimonials />

			*/}

			<Container>
				<div id="perks-table">
					<div className="top-section">
						<h2 className="line-center">
							{t('home_perks_heading1')}{' '}
							<span className="font-semibold">
								{t('home_perks_heading2_bold')}
							</span>{' '}
							{t('home_perks_heading3')}
						</h2>
						<p>
							<i>{t('home_perks_heading_text')}</i>
						</p>
					</div>
					<div className="perks-table-item">
						<h3>{t('home_perks_table_binding_time_heading')}</h3>
						<p>{t('home_perks_table_binding_time_text')}</p>
					</div>
					<div className="perks-table-item">
						<h3>{t('home_perks_table_transparent_heading')}</h3>
						<p>{t('home_perks_table_transparent_text')}</p>
					</div>
					<div className="perks-table-item">
						<h3>{t('home_perks_table_flexible_heading')}</h3>
						<p>{t('home_perks_table_flexible_text')}</p>
					</div>
					<div className="perks-table-item">
						<h3>{t('home_perks_table_clear_heading')}</h3>
						<p>{t('home_perks_table_clear_text')}</p>
					</div>
					<div className="perks-table-item">
						<h3>{t('home_perks_table_you_decide_heading')}</h3>
						<p>{t('home_perks_table_you_decide_text')}</p>
					</div>
					<div className="perks-table-item">
						<h3>{t('home_perks_table_personal_heading')}</h3>
						<p>{t('home_perks_table_personal_text')}</p>
					</div>
				</div>
			</Container>
			<div id="pay-hero">
				<div className="phone-bg"></div>
				<Container className="mymoney-pay-info">
					<h2 className={biggerThan700 ? 'line-left' : 'line-center'}>
						MyMoney
						<br />
						<span className="font-semibold">{t('home_pay_hero_heading')}</span>
					</h2>
					<p>
						<i>{t('home_pay_hero_text1_italic')}</i>
					</p>
					<p className="smaller">{t('home_pay_hero_text2')}</p>
					<p>
						<Link to="/pay#more-about-pay" className="button button--primary">
							{t('home_pay_hero_read_more_button')}
						</Link>
					</p>
				</Container>
			</div>
		</div>
	)
}

export default Home
