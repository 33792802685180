import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '../components/Container'
import carSvg from '../images/icons/5-pages-landing-page-expert-area-header-icons-car.svg'
import customersSvg from '../images/icons/5-pages-landing-page-expert-area-header-icons-customers.svg'
import applicationSvg from '../images/icons/5-pages-landing-page-icon-title-message-component-icons-application.svg'
import payoutSvg from '../images/icons/5-pages-landing-page-icon-title-message-component-icons-payout.svg'
import heroImage from '../images/pages/about/hero-image.webp'
import heroVideo from '../images/pages/about/hero-video.webm'

const About = () => {
	const { t } = useTranslation()
	return (
		<>
			<Hero />

			<Container className="prose py-20" id="read-more">
				<p>{t('about_paragraph1')}</p>
				<p>{t('about_paragraph2')}</p>
			</Container>

			<WeWillHelpYou />

			<Accomplishments />
		</>
	)
}

export default About

const Hero = () => {
	const { t } = useTranslation()
	return (
		<div className="about__hero py-20">
			<img src={heroImage} alt="" />
			<video
				src={heroVideo}
				autoPlay={true}
				muted={true}
				loop={true}
				preload="auto"
			/>
			<Container className="about__hero-text-container">
				<img width={160} src="/mymoney-logo.svg" alt="MyMoney logo" />
				<h2>{t('about_hero_heading')}</h2>
				<p>{t('about_hero_paragraph')}</p>
				<div>
					<Link to="#read-more" className="button button--primary">
						{t('about_hero_read_more_button')}
					</Link>
				</div>
			</Container>
		</div>
	)
}

const WeWillHelpYou = () => {
	const { t } = useTranslation()
	return (
		<div className="about__we-will-help">
			<Container className="py-20">
				<h2 className="line-center text-align-center">
					{t('about_we_will_help_heading1')}{' '}
					<strong>{t('about_we_will_help_heading2_strong')}</strong>
				</h2>
				<div className="about__we-will-help-items">
					<div>
						<img src={payoutSvg} height="64" alt="" />
						<p>{t('about_we_will_help_mikko_text')}</p>
						<p>/Mikko, {t('contact_mikko_title')}</p>
					</div>
					<div>
						<img src={applicationSvg} height="48" alt="" />
						<p>{t('about_we_will_help_karoliina_text')}</p>
						<p>/Karoliina, {t('contact_karoliina_title')}</p>
					</div>
				</div>
			</Container>
		</div>
	)
}

const Accomplishments = () => {
	const { t } = useTranslation()
	return (
		<Container className="py-20">
			<div className="about__accomplishments">
				<div className="prose mx-auto">
					<p>{t('about_accomplishments_paragraph')}</p>
				</div>

				<div className="about__accomplishments-items py-20">
					<div>
						<img src={carSvg} width={80} height={80} alt="" />
						<h3>
							<strong>2000+</strong>
							<br /> {t('about_accomplishments_item1')}
						</h3>
					</div>
					<div>
						<img src={customersSvg} width={80} height={80} alt="" />
						<h3>
							<strong>100k+</strong>
							<br />
							{t('about_accomplishments_item2')}
						</h3>
					</div>
				</div>
			</div>
		</Container>
	)
}
