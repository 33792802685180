import { useTranslation } from 'react-i18next'

import { Container } from '../../components/Container'

const PrivacyPolicy = () => {
	const { t, i18n } = useTranslation()

	const Body = body[i18n.language]

	return (
		<Container className="py-20">
			<div className="information-page privacy-policy-page">
				<h1 className="line-center text-align-center">
					{t('privacy_policy_heading')}
				</h1>
				<div className="prose prose-lg mx-auto" style={{ maxWidth: '40rem' }}>
					<Body />
				</div>
			</div>
		</Container>
	)
}

const HtmlTable = ({ head, body }) => {
	return (
		<div
			style={{
				overflowX: 'auto',
				width: 'calc(100% + 5rem)',
				maxWidth: 'calc(100vw - 5rem - 1.5rem)',
			}}
		>
			<div
				style={{
					display: 'inline-block',
					minWidth: '100%',
				}}
			>
				<table style={{ margin: 0 }} className="divide-y divide-gray-300">
					<thead>
						<tr className="divide-x divide-gray-200">
							{head.map((cell, index, arr) => (
								<th
									key={index}
									style={{
										verticalAlign: 'top',
										padding:
											index === 0
												? '1rem 1rem 1rem 0'
												: index === arr.length - 1
												? '1rem 0 1rem 1rem'
												: '1rem',
										borderRight: 'none',
									}}
								>
									{cell}
								</th>
							))}
						</tr>
					</thead>
					<tbody
						style={{ borderBottom: 0 }}
						className="divide-y divide-gray-200"
					>
						{body.map((row, index) => (
							<tr
								key={index}
								style={{ borderBottom: 'none' }}
								className="divide-x divide-gray-200"
							>
								{[
									...row,
									...Array.from({ length: head.length - row.length }, () => ''),
								].map((cell, index, arr) => (
									<td
										key={index}
										style={{
											padding:
												index === 0
													? '1rem 1rem 1rem 0'
													: index === arr.length - 1
													? '1rem 0 1rem 1rem'
													: '1rem',
											borderRight: 'none',
										}}
									>
										{Array.isArray(cell) ? (
											<ul style={{ marginTop: 0 }}>
												{cell.map((item, index) => (
													<li key={index} style={{ marginTop: 0 }}>
														{item}
													</li>
												))}
											</ul>
										) : (
											cell
										)}
									</td>
								))}
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
}

const body = {
	fi: () => {
		return (
			<>
				<h2>1. Johdanto</h2>
				<p>
					Yksityisyytesi on meille tärkeää, ja henkilötietojesi käsittely
					tapahtuu meillä turvallisesti. Tämä Tietosuojapolitiikka kuvaa, miten
					keräämme ja käytämme henkilötietojasi käyttäessäsi MyMoneyn palveluita
					(&quot;Palvelu&quot;) tai kun käsittelemme sinua koskevia
					henkilötietoja muissa yhteyksissä. Se kertoo myös oikeutesi ja miten
					voit käyttää niitä.
				</p>
				<p>
					Henkilötietojen käsittelystä vastaava taho MyMoney-tuotemerkin alla on
					MyMoney Oy, organisaationumero 3303458-1, Box 4305, SE-203 14 Malmö,
					Ruotsi. Tämä tarkoittaa, että MyMoney Europe AB on vastuussa
					henkilötietojesi käsittelystä sovellettavan tietosuojalainsäädännön
					eli yleisen tietosuoja-asetuksen (&quot;GDPR&quot;) ja täydentävän
					kansallisen tietosuojalainsäädännön mukaisesti.
				</p>
				<p>
					Mikäli sinulla on kysyttävää henkilötietojesi käsittelystä, voit aina
					ottaa meihin yhteyttä lähettämällä sähköpostia osoitteeseen{' '}
					<a href="mailto:privacy@mymoney.se">privacy@mymoney.se</a>.
				</p>

				<h2>2. Tärkeitä käsitteitä</h2>
				<p>
					Henkilötieto on mikä tahansa tieto, joka voidaan yhdistää elossa
					olevaan luonnolliseen henkilöön joko suoraan tai epäsuorasti yhdessä
					muiden tietojen kanssa. Esimerkkejä henkilötiedoista ovat
					henkilötunnus, nimi ja osoite, IP-osoite sekä sähköpostiosoite.
				</p>
				<p>
					Henkilötietojen käsittelyllä tarkoitetaan henkilötietoihin tai
					henkilötietojen yhdistelmään kohdistuvaa toimenpidettä tai toimintojen
					yhdistelmää riippumatta siitä, suoritetaanko ne automaattisesti vai
					ei. Esimerkkejä henkilötietojen käsittelystä ovat kerääminen,
					rekisteröinti, säilyttäminen ja käsittely.
				</p>

				<h2>3. Mitä tietoja keräämme ja mitä teemme niillä?</h2>

				<h3>3.1 Miten keräämme tietoja sinusta?</h3>
				<p>
					Tässä on yhteenveto niistä henkilötietojen tyypeistä, joita keräämme
					ja käsittelemme sinusta. Voit lukea tarkemmin alempana siitä, kuinka
					käsittelemme tietojasi eri yhteyksissä.
				</p>
				<p>
					<strong>Meille antamasi tiedot</strong>
				</p>
				<p>
					<u>Jos et ole asiakkaamme</u>: Kun olet täyttänyt henkilötietosi ja
					lähettänyt hakemuslomakkeen verkkosivuillamme, esimerkiksi nimen,
					henkilötunnuksen ja osoitteen, käsittelemme niitä rajoitetun ajan,
					jotta voimme vastata hakemushistoriaasi koskeviin kysymyksiin. Mikäli
					otat meihin yhteyttä esimerkiksi puhelimitse tai sähköpostitse,
					käsittelemme niitä henkilötietoja, jotka annat meille tässä
					yhteydessä.
				</p>
				<p>
					<u>Jos olet asiakkaamme</u>: Käsittelemme tietoja Palvelun
					tarjoamiseksi antaessasi meille henkilötietojasi osana Palvelun
					käyttämistä tai kun otat meihin yhteyttä, kuten oman tai kanssahakijan
					nimen, henkilötunnuksen ja osoitteen, tiedot tulostasi ja
					asuinmuodostasi ja niin edelleen. Keräämme myös henkilötietojasi,
					jotka luovutat jollekin jälleenmyyjistämme Palvelun käyttämiseksi.
				</p>
				<p>
					<strong>Tietoja, joita keräämme sinusta muista lähteistä</strong>
				</p>
				<p>
					<u>Jos olet asiakkaamme</u>: Kun käytät lainanvälityspalveluitamme,
					tarkistamme luottotietosi luottotietoyhtiöstä nimeltä Asiakastieto.
					Teemme tämän voidaksemme tarjota Palvelun. Luotonantajat, joiden
					kanssa teemme yhteistyötä, voivat joissakin tapauksissa hakea sinusta
					luottotietoraportin toiselta luottotietoyhtiöltä. Päivitämme myös
					säännöllisesti esimerkiksi osoitetietoja Asiakastiedon kautta.
				</p>
				<p>
					<u>Sinä, joka vierailet digitaalisissa kanavissamme</u>:
					Vieraillessasi digitaalisissa kanavissamme (kuten verkkosivustollamme)
					keräämme teknisiä tietoja, joihin voi sisältyä URL-osoite (jolla
					pääset kirjautumissivullesi), IP-osoitteesi, yksilöllisen
					laitetunnuksesi, käyttöhistoriasi, selaimesi tyyppi, kieli sekä
					tunnistus- ja käyttöjärjestelmätiedot. Teemme tämän helpottaaksemme,
					parantaaksemme ja kehittääksemme Palvelua sekä varmistaaksemme, että
					Palvelua käytetään oikein. Tällaisia tietoja kerätään osittain
					evästeiden avulla. Voit lukea lisää evästeiden käytöstä ja evästeiden
					kieltämisestä Evästekäytännöstämme, joka on saatavilla{' '}
					verkkosivuillamme <a href="https://mymoney.fi/">www.mymoney.fi</a>.
				</p>

				<h3>3.2 Henkilötietojen käsittely koskien kaikkia palveluita</h3>

				<HtmlTable
					head={[
						'Käsittelyn tarkoitus – mitä teemme ja miksi',
						'Tarkoitukseen käytettävät henkilötietotyypit ja niiden alkuperä',
						'Tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle',
						'Kuinka kauan säilytämme henkilötietojasi eri käsittelyissä',
					]}
					body={[
						[
							'Tarkastus ja vahvistus siitä, että olet se joka kerrot olevasi.',
							'Yhteystiedot ja tunnistetiedot',
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta)',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Ottaa sinuun yhteyttä sähköpostitse, tekstiviestillä, puhelimitse ja postitse Palvelun hallinnointia koskien.',
							['Nimi', 'Osoite', 'Puhelinnumero', 'Sähköposti.'],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta)',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Puhelun nauhoittaminen sopimuksen ja vahvistuksen dokumentoimiseksi ja kommunikaatiomme parantamiseksi.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Kaikki muut puhelun aikana antamasi tiedot sinusta.',
							],
							'Käsittely perustuu oikeutettuun etuun, jota arvioidaan ns. tasapainotestillä (Tietosuoja-asetuksen 6 artiklan 1 kohdan f alakohta). Tässä etujen punnitsemisen yhteydessä MyMoney on arvioinut, että meillä on oikeutettu etu dokumentoida viestintä asiakaspalvelumme kanssa.',
							'Säilytämme nauhoitetut puhelut 12 kuukauden ajan.',
						],
						[
							'Käsitellä asiakaspalvelukysymyksiä ja valituksia, joiden johdosta otat meihin yhteyttä.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus',
								'Kaikki muut tiedot, joita meidän on käsiteltävä asiasi käsittelemiseksi.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Käsittely jatkuu, kunnes tapaus on suljettu. Sen jälkeen säilytämme tapaukseen liittyviä henkilötietoja 2 vuoden ajan, jotta voimme tutustua sen historiaan ja lopputulokseen.',
						],
						[
							'Ylläpitää, kehittää, testata ja parantaa Palvelua ja teknisiä alustoja, joilla sitä tarjotaan.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Tarjota ja hallinnoida allekirjoitusratkaisua sopimusten tekemistä varten.',
							['Nimi', 'Henkilötunnus', 'Puhelinnumero', 'Tilinumero.'],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
					]}
				/>

				<h3>3.3 Henkilötietojen käsittely lainanvälityksen yhteydessä</h3>
				<p>
					Tässä kuvaamme, mitä henkilötietoja käsittelemme lainanvälityksen
					yhteydessä, verkkosivuillamme tai jollakin jälleenmyyjistämme, mihin
					tarkoituksiin käsittelemme niitä ja mikä on käsittelyn oikeusperuste.
				</p>

				<HtmlTable
					head={[
						'Käsittelyn tarkoitus – mitä teemme ja miksi',
						'Tarkoitukseen käytettävät henkilötietotyypit',
						'Tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle',
						'Kuinka kauan säilytämme henkilötietojasi eri käsittelyissä',
					]}
					body={[
						[
							'Lainahakemuksesi rekisteröiminen ja hallinnoiminen Palvelun tarjoamiseksi sinulle, mukaan lukien lainatarjousten esittäminen yhteistyötä kanssamme tekeville lainanantajille, ja sopimuksen toimeenpano sen lainanantajan kanssa, jonka kanssa teet lainasopimuksen.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus',
								'Tilinumero',
								'Työsuhdetta koskevat tiedot',
								'Asumista koskevat tiedot',
								'Tiedot kotitalouden kustannuksista',
								'Siviilisääty',
								'Lasten lukumäärä',
								'Muut hakemuksessa mahdollisesti annetut tiedot.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua. Jos peruutat tai kieltäydyt lainasta, voimme käsitellä tietojasi markkinointitarkoituksiin kohdassa 3.5 kuvatulla tavalla.',
						],
						[
							'Estää MyMoneyn toiminnan käyttäminen rahanpesun tai terrorismin rahoitamiseen toteuttamalla asiakkaan tuntemisen toimenpiteitä (ns. KYC-toimenpiteet), seuraamalla ja tarkistamalla liiketoimia ja tekemällä riskinarviointeja ja riskimallinnuksia. Tämä käsittely on profilointia, ja päätökset rahanpesuriskistä ovat niin sanottuja automaattisia päätöksiä.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus',
								'Tilinumero',
								'Työsuhdetta koskevat tiedot',
								'Asumista koskevat tiedot',
								'Tiedot kotitalouden kustannuksista',
								'Siviilisääty',
								'Lasten lukumäärä',
								'Muut hakemuksessa mahdollisesti annetut tiedot',
								'Luottotiedot (asiakastietosta tiedot, esim. vuositulot, jos sinulla on maksuhäiriöitä)',
								'Otteet EU:n pakoteluetteloista ja PEP-rekisteristä.',
							],
							'Lainsäädäntö velvoittaa meidät selvittämään asiakkaidemme henkilöllisyyden (Tietosuoja-asetuksen 6 artiklan 1 kohdan c alakohta) (laki (28.6.2017/444) rahanpesun ja terrorismin rahoittamisen estämisestä).',
							'Säilytämme tietojasi vähintään 5 vuotta ja enintään 10 vuotta hakemuksen tekemisestä rahanpesun ja terrorismin rahoittamisen estämisestä annetun lain (28.6.2017/444) mukaisesti.',
						],
						[
							'Lainahakemuksen ja luottotietoraportin tietojen analysointi sen selvittämiseksi, oletko oikeutettu lainaan. Tämä on profilointia, ja päätös lainan hyväksymistä tai hyväksymättä jättämisesti voi olla automaattinen päätös.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus',
								'Tilinumero',
								'Työsuhdetta koskevat tiedot',
								'Asumista koskevat tiedot',
								'Tiedot kotitalouden kustannuksista',
								'Siviilisääty',
								'Lasten lukumäärä',
								'Muut hakemuksessa mahdollisesti annetut tiedot',
								'Luottotiedot (asiakastietosta tiedot, esim. vuositulot, jos sinulla on maksuhäiriöitä)',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Siirtääksemme lainahakemuksen lainanantajille, joiden kanssa teemme yhteistyötä ja joiden lainanottajan kriteerit täytät.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus',
								'Tilinumero',
								'Työsuhdetta koskevat tiedot',
								'Asumista koskevat tiedot',
								'Tiedot kotitalouden kustannuksista',
								'Siviilisääty',
								'Lasten lukumäärä',
								'Muut hakemuksessa mahdollisesti annetut tiedot',
								'Luottotiedot (asiakastietosta tiedot, esim. vuositulot, jos sinulla on maksuhäiriöitä)',
								'Otteet EU:n pakoteluetteloista ja PEP-rekistereistä.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Palvelua koskevien petoksien ja väärinkäytöksien ehkäisemiseksi, havaitsemiseksi ja torjumiseksi.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Sähköposti',
								'Henkilötunnus',
								'Muut tarvittavat tiedot.',
							],
							'Käsittely perustuu oikeutettuun etuun, jota arvioidaan ns. tasapainotestillä (Tietosuoja-asetuksen 6 artiklan 1 kohdan f alakohta). MyMoney on arvioinut etujen punnitsemisessa yhteydessä, että meillä on oikeutettu etu käsitellä henkilötietoja petosten torjumiseksi.',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
					]}
				/>

				<h3>3.4 Henkilötietojen käsittely vakuutusvälityksen yhteydessä</h3>
				<p>
					Tässä kuvaamme, mitä henkilötietoja käsittelemme vakuutusvälityksen
					yhteydessä, mihin tarkoituksiin niitä käsittelemme ja mikä on
					käsittelyn oikeusperuste.
				</p>
				<HtmlTable
					head={[
						'Käsittelyn tarkoitus – mitä teemme ja miksi',
						'Tarkoitukseen käytettävät henkilötietotyypit ja niiden alkuperä',
						'Tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle',
						'Kuinka kauan säilytämme henkilötietojasi eri käsittelyissä',
					]}
					body={[
						[
							'Vakuutushakemuksesi rekisteröinti ja hallinnointi, jotta voimme tarjota sinulle Palvelua sopimuksemme mukaisesti.',
							[
								'Nimi',
								'Yhteystiedot (esim. osoite, puhelinnumero, sähköposti)',
								'Henkilötunnus',
								'Tilinumero',
								'Kaikki muut tiedot, jotka annat rekisteröityessäsi vakuutukseen.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Vakuutustietojen siirtäminen vakuutusyhtiölle.',
							[
								'Nimi',
								'Yhteystiedot (esim. osoite, puhelinnumero, sähköposti)',
								'Henkilötunnus',
								'Tilinumero',
								'Kaikki muut tiedot, jotka annat rekisteröityessäsi vakuutukseen.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
					]}
				/>

				<h3>3.5 Henkilötietojen käsittely markkinoinnin yhteydessä</h3>
				<p>
					Tässä kuvaamme, mitä henkilötietoja käsittelemme markkinoinnin
					yhteydessä, mihin tarkoituksiin käsittelemme niitä ja mikä on
					käsittelyn oikeudellinen peruste.
				</p>
				<HtmlTable
					head={[
						'Käsittelyn tarkoitus – mitä teemme ja miksi',
						'Tarkoitukseen käytettävät henkilötietotyypit ja niiden alkuperä',
						'Tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle',
						'Kuinka kauan säilytämme henkilötietojasi eri käsittelyissä',
					]}
					body={[
						[
							'Päättääksemme, millaista markkinointia meidän tulisi tarjota sinulle. Voit ilmoittaa meille, jos et halua meidän käsittelevän tietojasi tällä tavoin. Tällöin lopetamme tietojasi käytön markkinointitarkoituksiin. Yhteystiedot löytyvät kohdasta 7. Käsittely voi olla profilointia.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Henkilötunnus',
								'Tietoja palveluidemme käytöstä.',
							],
							'Käsittely perustuu oikeutettuun etuun, jota arvioidaan ns. tasapainotestillä (Tietosuoja-asetuksen 6 artiklan 1 kohdan f alakohta). MyMoney on arvioinut etujen punnitsemisen yhteydessä, että meillä on oikeutettu etu päättää, mitä markkinointia tarjoamme sinulle. Varmistamme, että käsittely on tarpeen oikeutetun edun toteuttamiseksi ja että etumme on tärkeämpi kuin oikeutesi olla joutumatta käsittelyn kohteeksi.',
							'Kunnes ilmoitat meille, että et ole kiinnostunut käsittelystä.',
						],
						[
							'Markkinoidaksemme ja tarjotaksemme sinulle muita meidän tai konsernimme yrityksesten palveluita. Jos et halua saada meiltä markkinointia, voit ilmoittaa siitä ottamalla meihin yhteyttä. Tällöin lopetamme tietojasi käytön markkinointitarkoituksiin. Yhteystiedot löytyvät kohdasta 7.',
							[
								'Nimi',
								'Osoite',
								'Puhelinnumero',
								'Henkilötunnus',
								'Tietoja palveluidemme käytöstä.',
							],
							'Käsittely perustuu oikeutettuun etuun, jota arvioidaan ns. tasapainotestillä (Tietosuoja-asetuksen 6 artiklan 1 kohdan f alakohta). MyMoney on arvioinut etujen punnitsemisen yhteydessä, että meillä on oikeutettu etu päättää, mitä markkinointia tarjoamme sinulle. Varmistamme, että käsittely on tarpeen oikeutetun edun toteuttamiseksi ja että etumme on tärkeämpi kuin oikeutesi olla joutumatta käsittelyn kohteeksi.',
							'Kunnes ilmoitat meille, että et ole kiinnostunut käsittelystä.',
						],
						[
							'Markkinoidaksemme ja tarjotaksemme sinulle muita meidän tai konsernimme yrityksesten palveluita. Jos et halua saada meiltä markkinointia, voit ilmoittaa siitä ottamalla meihin yhteyttä. Tällöin lopetamme tietojasi käytön markkinointitarkoituksiin. Yhteystiedot löytyvät kohdasta 7.',
							['Nimi', 'Osoite', 'Puhelinnumero', 'Sähköpostiosoite'],
							'Käsittely perustuu suostumukseesi (Tietosuoja-asetuksen 6 artiklan 1 kohdan a alakohta).',
							'Kun ilmoitat meille, että haluat peruuttaa suostumuksesi, tai kun kerrot meille, että et ole enää kiinnostunut tästä käsittelystä.',
						],
					]}
				/>

				<h3>
					3.6 Henkilötietojen käsittely digitaalisten kanaviemme vierailujen
					yhteydessä
				</h3>
				<p>
					Tässä kuvaamme, mitä henkilötietoja käsittelemme digitaalisten
					kanaviemme (kuten verkkosivustomme) käytön yhteydessä, mihin
					tarkoitukseen ja mikä on käsittelyn oikeusperuste. Tässä osiossa
					olevat tiedot kerätään osittain evästeiden avulla. Voit lukea lisää
					evästeiden käytöstä ja evästeiden hylkäämisestä evästekäytännöstämme,
					joka on saatavilla verkkosivuillamme{' '}
					<a href="https://mymoney.fi/evasteet">https://mymoney.fi/evasteet</a>.
				</p>
				<HtmlTable
					head={[
						'Käsittelyn tarkoitus – mitä teemme ja miksi',
						'Tarkoitukseen käytettävät henkilötietotyypit ja niiden alkuperä',
						'Tietosuoja-asetuksen mukainen oikeusperuste henkilötietojen käsittelylle',
						'Kuinka kauan säilytämme henkilötietojasi eri käsittelyissä',
					]}
					body={[
						[
							'My Page -palvelun ja sen toimintojen tarjoaminen.',
							[
								'Nimi',
								'Yhteystiedot (esim. osoite, puhelinnumero, sähköpostiosoite)',
								'Henkilötunnus',
								'Tilinumero',
								'Laitteen tiedot',
								'Tiedot lainastasi.',
							],
							'Käsittely on tarpeen kanssasi tehdyn sopimuksen toimeenpanemiseksi (Tietosuoja-asetuksen 6 artiklan 1 kohdan b alakohta).',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Analysoida ja arvioida, miten verkkosivustomme ja sen toimintoja käytetään ja miten niitä voidaan parantaa. Jos mahdollista anonymisoimme tiedot ensin, mikä tarkoittaa, että henkilötietojasi ei käsitellä myöhemmin. Käsittely voi olla profilointia.',
							['Laitteen tiedot', 'Tietoja palveluidemme käytöstä.'],
							'Käsittely perustuu oikeutettuun etuun, jota arvioidaan ns. tasapainotestillä (Tietosuoja-asetuksen 6 artiklan 1 kohdan f alakohta). MyMoney on arvioinut etujen punnitsemisen yhteydessä, että meillä on oikeutettu etu päättää, mitä markkinointia tarjoamme sinulle. Varmistamme, että käsittely on tarpeen oikeutetun edun toteuttamiseksi ja että etumme on tärkeämpi kuin oikeutesi olla joutumatta käsittelyn kohteeksi.',
							'Tämä käsittely kestää niin kauan kuin käytät palvelua.',
						],
						[
							'Petostentorjunta-arviointien tekeminen MyMoneyn digitaalisia palveluja käytettäessä. Tämä prosessi sisältää profilointia ja automatisoitua päätöksentekoa. Käytämme automatisoitua päätöksentekoa tähän tarkoitukseen määrittääksemme, muodostatko petosriskin.',
							[
								'Nimi',
								'Yhteystiedot (esim. osoite, puhelinnumero, sähköpostiosoite)',
								'Henkilötunnus',
								'Tilinumero',
								'Tiedot lainastasi',
								'Laitteen tiedot',
								'Muut sinua koskevat tiedot, jotka on täytetty palveluissamme.',
							],
							'Käsittely perustuu oikeutettuun etuun, jota arvioidaan ns. tasapainotestillä (Tietosuoja-asetuksen 6 artiklan 1 kohdan f alakohta). MyMoney on arvioinut etujen punnitsemisen yhteydessä, että meillä on oikeutettu etu käsitellä henkilötietoja petosten torjumiseksi.',
							'Kun petosarviointi suoritetaan.',
						],
					]}
				/>

				<h3>3.7 Henkilötietojen käsittely rekrytoinnin yhteydessä</h3>
				<p>
					Jos haet meidän tai palkkaamamme rekrytointikumppanin julkaiseman
					työpaikkailmoituksen kautta tai lähetät meille oma-aloitteisen
					työhakemuksen, käsittelemme henkilötietojasi, jotta voimme tarkastella
					soveltuvuuttasi kyseiseen tehtävään ja käyttää hakemustasi tulevissa
					avoimissa työpaikoissa, joiden uskomme sopivan sinulle. Käsittelemämme
					henkilötiedot ovat tyypillisesti nimesi, osoitteesi, puhelinnumerosi,
					sähköpostiosoitteesi sekä tiedot, jotka annat ansioluettelossasi ja
					henkilökohtaisessa kirjeessäsi, tutkintotodistuksessasi ja muissa
					todistuksissa ja asiakirjoissa, jotka haluat lähettää meille.
					Käsittely perustuu etujen tasapainoon (Tietosuoja-asetuksen artiklan 1
					kohdan 1 alakohta f). Etujen punnitsemisen yhteydessä MyMoney on
					arvioinut, että meillä on oikeutettu etu käsitellä henkilötietoja
					rekrytointitarkoituksiin. Säilytämme tietojasi 1 vuoden ajan niiden
					lähettämispäivästä, ellet pyydä meitä poistamaan niitä sitä ennen.
				</p>

				<h3>3.8 Automaattisesta päätöksenteosta tarkemmin</h3>
				<p>
					Automaattinen päätöksenteko tarkoittaa päätöstä, joka tehdään
					yksinomaan henkilötietojesi automaattisen käsittelyn perusteella. Me
					ja lainanantajat, joiden kanssa teemme yhteistyötä, käytämme
					automaattista päätöksentekoa jättäessäsi lainahakemuksen. Tämä
					tarkoittaa sitä, että antamasi tiedot ja tietoja, jotka saamme muista
					lähteistä sinusta ja mahdollisista kanssahakijoista, sovitetaan
					automaattisesti kriteereihin, joita me ja lainanantajat edellytämme
					lainan myöntämiseksi. Näitä ovat esimerkiksi tulot, työsuhteen tyyppi,
					haettu lainasumma ja vastaavat tiedot. Jos et täytä tietyn
					lainanantajan asettamia kriteerejä, hakemuksesi hylätään
					automaattisesti eikä sitä toimiteta lainanantajalle.
				</p>
				<p>
					Joissakin tilanteissa sinulla on oikeus pyytää manuaalista
					päätöksentekoprosessia. Tässä tapauksessa ota meihin yhteyttä alla
					olevilla yhteystiedoilla. Voit myös ottaa yhteyttä tiettyyn
					lainanantajaan saadaksesi lisätietoja siitä, miten he käyttävät
					automaattista päätöksentekoa tai jos sinulla on kysyttävää tietyn
					lainanantajan henkilötietojen käsittelystä.
				</p>
				<p>
					Automaattisen päätöksenteon tarkoituksena on tarjota oikeudenmukainen
					ja täsmällinen lainanvälityspalvelu, mikä on välttämätöntä kanssasi
					tekemämme sopimuksen täyttämiseksi. Jos haluat riitauttaa tekemämme
					automaattisen päätöksen ja pyytää manuaalista käsittelyä, ota yhteyttä
					meihin osoitteeseen{' '}
					<a href="mailto:privacy@mymoney.se">privacy@mymoney.se</a>.
				</p>

				<h3>3.9 Profiloinnista tarkemmin</h3>
				<p>
					Profiloinnilla tarkoitetaan nykyisten tai potentiaalisten asiakkaiden
					henkilötietojen automaattista käsittelyä, jonka tarkoituksena on
					arvioida heidän tiettyjä henkilökohtaisia ominaispiirteitään. Tämä on
					edellytyksenä, jotta voidaan analysoida tai ennustaa esimerkiksi
					heidän taloudellista tilannettaan, henkilökohtaisia mieltymyksiä,
					kiinnostuksen kohteita ja sijaintia. Käytämme profilointia muun muassa
					markkina- ja asiakasanalyyseihin, järjestelmäkehitykseen,
					markkinointiin, automaattiseen päätöksentekoon (ks. edellä) ja
					transaktioiden seurantaan petosten estämiseksi. Yksityiskohtaiset
					tiedot MyMoneyn profiloinnin tarkoituksesta, henkilötiedoista jne. on
					kuvattu tarkemmin edellä olevissa taulukoissa kunkin käsittelyn
					kohdalla. Profilointi tapahtuu joissakin tapauksissa evästeiden
					avulla. Voit lukea lisää evästeiden käytöstä ja evästeiden
					kieltämisestä evästekäytännöstämme, joka on saatavilla
					verkkosivustollamme{' '}
					<a href="https://mymoney.fi/evasteet">https://mymoney.fi/evasteet</a>.
				</p>

				<h3>3.10 Suostumuksesta tarkemmin</h3>
				<p>
					Voit antaa nimenomaisen suostumuksen, jotta voimme käsitellä
					henkilötietojasi suostumuksen tuella. Esimerkki tällaisesta
					tilanteesta on suoramarkkinointi.
				</p>
				<p>
					Sinulla on oikeus peruuttaa suostumuksesi milloin tahansa. Tällöin
					meillä ei ole enää oikeutta käsitellä tietoja suostumuksesi
					perusteella. Jos haluat peruuttaa suostumuksesi, löydät yhteystietomme
					alempana.
				</p>

				<h2>4. Kenen kanssa voimme jakaa tietojasi?</h2>
				<p>
					Siirtäessämme tai luovuttaessamme valituille kolmansille osapuolille
					henkilötietojasi, toteutamme kaikki kohtuulliset sopimukselliset,
					oikeudelliset, tekniset ja organisatoriset toimenpiteet
					varmistaaksemme, että henkilötietojasi käsitellään turvallisesti ja
					riittävällä suojaustasolla. Tällaisia kolmansia osapuolia ovat:
				</p>

				<p>
					<strong>Palvelutoimittajat</strong>. Jotkut palvelutoimittajistamme
					tarjoavat palveluita, joissa henkilötietojesi käsittely on
					välttämätöntä. Näitä ovat esimerkiksi sähköisten allekirjoitusten ja
					sähköisen tunnistuksen tarjoajat, digitaalisesti tai postitse sinulle
					ilmoituksia välittävät palveluntarjoajat, sekä IT-palvelujen
					tarjoajat, jotka ovat välttämättömiä Palvelun tarjoamiseksi.
				</p>
				<p>
					<strong>Asiakastieto ja vastaavat toimijat</strong>. Kun käytät
					lainanvälityspalveluamme, henkilötietojasi voidaan siirtää ja
					luovuttaa luottotietoyhtiöille luottokelpoisuutesi arvioimiseksi.
					Henkilötietoja voidaan myös siirtää ja luovuttaa henkilöllisyys- ja
					petostentorjuntapalvelujen tarjoajille henkilöllisyytesi ja osoitteesi
					vahvistamiseksi sekä petoksilta suojaamiseksi.
				</p>
				<p>
					<strong>Viranomaiset</strong>. Voimme luovuttaa tarpeellisia tietoja
					viranomaisille, kuten poliisille, valvontaviranomaiselle tai muille
					viranomaisille lain niin edellyttäessä. Laki esimerkiksi velvoittaa
					toimittamaan tietoja rahanpesun ja terrorismin rahoittamisen vastaisia
					toimenpiteitä varten.
				</p>
				<p>
					<strong>Lainanantajat</strong>. Lainavertailussa luovutamme
					hakemuksesi niille lainanantajille, joiden kanssa teemme yhteistyötä
					ja joiden kriteerit vastaavat hakemustasi. Hakemuksesi vastaanottaneet
					lainanantajat ovat rekisterinpitäjiä toteuttamansa henkilötietojesi
					käsittelyn osalta.
				</p>
				<p>
					<strong>Jälleenmyyjät</strong>. Jos jätät jollekin jälleenmyyjistämme
					lainahakemuksen, kyseinen jälleenmyyjä käsittelee henkilötietojasi
					MyMoneyn käsittelijänä Palvelun tarjoamiseksi sinulle.
				</p>
				<p>
					<strong>Konserniyhtiöt</strong>. Voimme siirtää ja luovuttaa tietoja
					konserniyhtiöidemme kanssa tehostaaksemme sisäisiä prosesseja ja
					laatiaksemme yhteisiä tilastoja sekä markkinoidaksemme muita rahoitus-
					ja vakuutustuotteita
				</p>
				<p>
					<strong>Liiketoiminnan luovutus</strong>. Jos myymme tai ostamme
					jonkin liiketoiminnan, voimme luovuttaa henkilötietojasi tällaisen
					liiketoiminnan mahdolliselle myyjälle tai ostajalle. Jos kolmas
					osapuoli ostaa meidät tai merkittävän osan liiketoiminnastamme,
					asiakkaidemme henkilötietoja saatetaan jakaa. Varmistamme ennen
					tällaista jakamista, että asianmukaiset salassapitositoumukset ovat
					voimassa.
				</p>

				<h2>5. Missä käsittelemme henkilötietojasi?</h2>
				<p>
					Käsittelemme henkilötietojasi pääasiassa EU/ETA-alueella.
					Poikkeustapauksissa henkilötietoja voidaan siirtää ja käsitellä
					EU/ETA:n ulkopuolisissa maissa, niin sanotuissa kolmansissa maissa.
					Yritykset, jotka käsittelevät henkilötietoja puolestamme,
					allekirjoittavat aina kanssamme sopimukset, joilla varmistamme
					henkilötietojesi korkeatasoisen suojan. EU/ETA-alueen ulkopuolisten
					yhteistyökumppaneiden osalta toteutetaan erityisiä suojatoimenpiteitä
					muun muassa tekemällä sopimuksia, jotka sisältävät Euroopan komission
					tiedonsiirtoa koskevat mallisopimuslausekkeet ja joiden tavoitteena on
					varmistaa vastaava henkilötietojesi suojataso kuin mikä tarjotaan
					EU/ETA-alueella. Siirto kolmanteen maahan voi perustua myös Euroopan
					komission päätökseen, että maassa on riittävä henkilötietojen suojan
					taso (niin sanottu vastaavuuspäätös).
				</p>
				<p>
					Tiedot maista, joiden osalta EU:n komissio on tehnyt
					vastaavuuspäätöksiä, löytyvät täältä:
				</p>
				<p>Riittävyyttä koskevat päätökset | Euroopan komissio (europa.eu)</p>
				<p>
					<a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en">
						https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_en
					</a>
				</p>
				<p>EU:n komission mallisopimuslausekkeet ovat saatavilla täältä:</p>
				<p>Vakiosopimuslausekkeet (SCC) | Euroopan komissio (europa.eu)</p>
				<p>
					<a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_sv">
						https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_sv
					</a>
				</p>
				<p>
					Voit ottaa yhteyttä osoitteeseen{' '}
					<a href="mailto:privacy@mymoney.se">privacy@mymoney.se</a> saadaksesi
					lisätietoja siirroista kolmansiin maihin.
				</p>

				<h2>6. Kuinka kauan säilytämme henkilötietojasi?</h2>
				<ul>
					<li>
						<p>
							Henkilötietojasi säilytetään vain niin kauan kuin se on käsittelyn
							tarkoituksen kannalta tarpeellista tai kuin meitä vaaditaan
							säilyttämään se sovellettavan lainsäädännön mukaisesti.
						</p>
					</li>
					<li>
						<p>
							Palvelun suorittamiseen tarvittavia henkilötietoja säilytetään
							niin kauan kuin ne ovat tarpeen kanssasi tekemämme sopimuksen
							täyttämiseksi.
						</p>
					</li>
					<li>
						<p>
							Henkilötietoja, jotka meidän on lain mukaan säilytettävä
							rahanpesusääntelyn vaatimusten täyttämiseksi, säilytetään
							vähintään 5 vuotta ja enintään 10 vuotta sopimussuhteen
							päättymisestä.
						</p>
					</li>
					<li>
						<p>
							Kirjanpitoa varten tarpeellisia henkilötietoja säilytetään 7
							vuotta sen kalenterivuoden lopusta, jona tilikausi päättyi.
						</p>
					</li>
					<li>
						<p>
							Henkilötietoja, joita tarvitaan tietyn lainanantajan kanssa tehdyn
							sopimuksen täyttämiseksi, säilytetään niin kauan kuin se on
							tarpeen lainanantajan kanssa tehdyn sopimuksen täyttämiseksi,
							kuitenkin enintään 10 vuotta sopimussuhteen päättymisestä.
						</p>
					</li>
					<li>
						<p>
							Henkilötietoja, joita käytämme markkinointitarkoituksiin
							suostumuksen perusteella, säilytetään niin kauan kuin suostumus on
							voimassa.
						</p>
					</li>
				</ul>

				<h2>7. Mitä oikeuksia sinulla on?</h2>
				<p>
					<strong>Oikeus päästä tietoihisi</strong>
				</p>
				<p>
					Voit pyytää kopion tiedoistasi halutessasi tietää, mitä tietoja meillä
					on sinusta. Tätä kutsutaan &quot;rekisteriotteeksi&quot;.
				</p>
				<p>
					<strong>Oikeus oikaisuun</strong>
				</p>
				<p>
					Sinulla on oikeus saada virheelliset henkilötiedot oikaistua tai
					täydentää puutteellisia henkilötietojasi.
				</p>
				<p>
					<strong>Oikeus tulla poistetuksi</strong>
				</p>
				<p>
					Sinulla on oikeus pyytää tiettyjen henkilötietojen poistamista.
					Huomaa, että joissakin tapauksissa emme voi poistaa tietojasi,
					esimerkiksi jos niitä vaaditaan lakisääteisten velvoitteiden
					täyttämiseksi tai oikeudellisten vaatimusten laatimiseksi,
					esittämiseksi tai puolustamiseksi.
				</p>
				<p>
					<strong>Oikeus käsittelyn rajoittamiseen</strong>
				</p>
				<p>
					Sinulla on oikeus pyytää henkilötietojesi käsittelyn rajoittamista,
					esimerkiksi jos kyseenalaistat tietojen oikeellisuuden.
				</p>
				<p>
					<strong>Oikeus vastustaa käsittelyä</strong>
				</p>
				<p>
					Jos katsomme, että meillä on oikeutettu etu henkilötietojesi
					käsittelyyn, voit milloin tahansa vastustaa käsittelyä. Jos päätät
					vastustaa käsittelyä, emme saa enää käsitellä henkilötietojasi
					kyseiseen tarkoitukseen, ellemme pysty osoittamaan oikeutettua etua
					käsittelylle. Tällaisen oikeutetun edun on oltava vahvempi kuin
					intressisi siihen, että henkilötietojasi ei käsitellä
					tietosuojasyistä. Voit myös aina vastustaa käsittelyä, jota suoritamme
					suoramarkkinointitarkoituksessa.
				</p>
				<p>
					<strong>Oikeus tietojen siirrettävyyteen</strong>
				</p>
				<p>
					Sinulla on oikeus saada meille antamasi henkilötiedot ja/tai vaatia,
					että henkilötietosi siirretään toiselle rekisterinpitäjälle.
					Henkilötietojen tulee olla jäsennellyssä, yleisesti käytetyssä ja
					koneellisesti luettavassa muodossa. Tiedon siirrettävyyden
					edellytyksenä on, että siirto on teknisesti mahdollista ja että se
					voidaan automatisoida.
				</p>
				<p>
					<strong>Oikeus tehdä valitus</strong>
				</p>
				<p>
					Jos sinulla on kommentteja tai valituksia henkilötietojesi
					käsittelystä tai jos haluat käyttää oikeuksiasi, ota yhteyttä
					osoitteeseen{' '}
					<a href="mailto:privacy@mymoney.se">privacy@mymoney.se</a>.
				</p>
				<p>
					Jos emme kaikesta huolimatta onnistu löytämään ratkaisua yhdessä, voit
					ottaa yhteyttä Suomen Tietosuojavaltuutetun toimistoon, joka on
					henkilötietojen käsittelyn valvontaviranomainen:
				</p>
				<p>Osoite: Lintulahdenkuja 4, 00530 Helsinki, Suomi</p>
				<p>
					Sähköposti: <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi</a>
				</p>
				<p>Puhelin: +358 29 566 6700</p>
				<p>
					Verkkosivusto: <a href="https://tietosuoja.fi/">www.tietosuoja.fi</a>
				</p>
				<h2>8. Muutokset tietosuojakäytäntöön</h2>
				<p>
					Pidätämme oikeuden muuttaa ja päivittää tietosuojakäytäntöämme. Uusin
					versio on aina saatavilla verkkosivuillamme{' '}
					<a href="https://www.mymoney.se">www.mymoney.se</a>. Jos päivitykset
					ovat ratkaisevan tärkeitä henkilötietojesi käsittelyn kannalta,
					sinulle ilmoitetaan muutoksista hyvissä ajoin ennen päivitysten
					voimaantuloa. Jos sinulla on kommentteja päivityksien seurauksista
					henkilötietojesi käsittelylle, voit ottaa meihin yhteyttä osoitteessa{' '}
					<a href="mailto:privacy@mymoney.se">privacy@mymoney.se</a>.
				</p>
			</>
		)
	},
	sv: () => {
		return (
			<>
				<h2 id="1-inledning">
					<span className="sr-only">1. Inledning</span>
				</h2>
				<p>
					Din personliga integritet är viktig för oss och behandlingen av dina
					personuppgifter ska ske säkert.
				</p>
				<p>
					Denna Dataskyddspolicy förklarar hur vi samlar in och använder dina
					personuppgifter när du använder MyMoneys tjänster
					(&quot;Tjänsten&quot;) eller då vi i andra sammanhang behandlar
					personuppgifter om dig. Den beskriver också dina rättigheter och hur
					du kan göra dina rättigheter gällande.
				</p>
				<p>
					Personuppgiftsansvarig för den behandling av personuppgifter som sker
					under varumärket MyMoney är MyMoney Europe AB, org nr 556702-1679, Box
					4305, 203 14 MALMÖ. Det betyder att MyMoney Europe AB ansvarar för att
					behandlingen av dina personuppgifter sker enligt gällande
					dataskyddslagstiftning, det vill säga dataskyddsförordningen
					(&quot;GDPR&quot;) och kompletterande nationell lagstiftning.
				</p>
				<p>
					Du kan alltid kontakta oss för eventuella frågor om vår behandling av
					dina personuppgifter genom att skicka ett e-postmeddelande till{' '}
					<a href={`mailto:privacy@mymoney.se`}>privacy@mymoney.se</a>.
				</p>

				<h2 id="2-viktiga-begrepp">2. Viktiga begrepp</h2>
				<p>
					En personuppgift är varje upplysning som direkt, eller indirekt
					tillsammans med andra uppgifter, kan kopplas till en levande person.
					Exempel på personuppgifter är personnummer, namn och adress, IP adress
					och e-postadress.
				</p>
				<p>
					Med behandling av personuppgifter menas en åtgärd eller kombination av
					åtgärder beträffande personuppgifter eller uppsättningar av
					personuppgifter, oberoende av om de utförs automatiserat eller ej.
					Exempel på behandling av personuppgifter är insamling, registrering,
					lagring och bearbetning.
				</p>

				<h2 id="3-vilken-information-samlar-vi-in-och-vad-g-r-vi-med-den">
					3. Vilken information samlar vi in och vad gör vi med den?
				</h2>

				<h3 id="3-1-hur-samlar-vi-in-information-om-dig">
					3.1 Hur samlar vi in information om dig?
				</h3>
				<p>
					Här sammanfattar vi vilka slags personuppgifter vi samlar in och
					behandlar om dig. Längre ner i policyn kan du läsa mer i detalj om hur
					vi behandlar dina uppgifter i olika sammanhang.
				</p>
				<p>
					<strong>Information som du själv ger till oss</strong>
				</p>
				<p>
					<u>Du som inte är kund hos oss:</u> När du fyllt i dina
					personuppgifter och skickat in ett ansökningsformulär på vår hemsida,
					t.ex. namn, personnummer och adress, behandlar vi dessa under en
					begränsad tid för att kunna besvara frågor om din ansökningshistorik.
					Om du kontaktar oss via t.ex. telefon eller mail behandlar vi de
					personuppgifter du där anger.
				</p>
				<p>
					<u>Du som är kund hos oss:</u> Du lämnar själv aktivt personuppgifter
					till oss när du använder Tjänsten eller kontaktar oss, t.ex. namn,
					personnummer och adress för dig och din eventuella medsökande, inkomst
					och boendeform, osv. Vi behandlar dessa uppgifter för att
					tillhandahålla Tjänsten. Vi samlar också in dina personuppgifter som
					du själv uppger hos någon av våra återförsäljare i syfte att använda
					Tjänsten.
				</p>
				<p>
					<strong>Information som vi samlar in om dig från andra källor</strong>
				</p>
				<p>
					<u>Du som är kund hos oss:</u> När du ansöker om låneförmedling hos
					oss tar vi en kreditupplysning på dig från kreditupplysningsföretaget
					UC AB. Det gör vi för att kunna tillhandahålla Tjänsten. Långivare som
					vi samarbetar med kan i vissa fall ta en kreditupplysning på dig från
					något annat kreditupplysningsföretag. Vi uppdaterar löpande också
					aktuella adressuppgifter från t. ex. Creditsafe och Syna.
				</p>
				<p>
					<u>Du som besöker våra digitala kanaler:</u> Vi samlar in tekniska
					data då du besöker våra digitala kanaler (t.ex. vår webbplats), vilka
					kan omfatta den URL som är din unika åtkomst till din inloggningssida,
					din IP-adress, unique device ID, användningshistorik, typ av
					webbläsare, språk och information om identifiering och operativsystem.
					Detta gör vi för att underlätta, förbättra och vidareutveckla Tjänsten
					samt säkerställa att Tjänsten används på korrekt sätt. Sådan
					information samlas delvis in genom cookies. Du kan läsa mer om hur vi
					använder cookies och hur du går tillväga för att avböja cookies i vår
					Cookiepolicy som finns tillgänglig på vår webbplats{' '}
					<a href="https://www.mymoney.fi/">www.mymoney.fi</a>.
				</p>

				<h3 id="3-2-personuppgiftsbehandling-i-anslutning-till-laneformedling">
					3.2 Personuppgiftsbehandling i anslutning till låneförmedling
				</h3>
				<HtmlTable
					head={[
						'Syftet med behandlingen – vad vi gör och varför',
						'Typer av personuppgifter som används för syftet, samt var de kommer ifrån',
						'Laglig grund enligt GDPR för att behandla personuppgifterna',
						'Hur länge vi sparar dina personuppgifter i de olika behandlingarna',
					]}
					body={[
						[
							'Kontrollera och verifiera att du är den du utger dig för att vara.',
							'Kontakt- och identifikationsuppgifter.',
							'Behandlingen är nödvändig för att MyMoney ska kunna fullgöra avtalet med dig (Artikel 6(1)(b) GDPR.',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Denna behandling pågår så länge du använder vår tjänst.',
							['Namn', 'Adress', 'Telefonnummer', 'E-post'],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att spela in telefonsamtal i syfte att dokumentera och säkerställa eventuella överenskommelser och samtycken med dig och förbättra vår kommunikation.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Andra ev. uppgifter om dig som du lämnar i samtalet.',
							],
							'Behandlingen är baserad på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid den intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att dokumentera kommunikation med vår kundtjänst.',
							'Vi sparar inspelade samtal i 12 månader.',
						],
						[
							'Att hantera kundtjänstärenden och klagomål som du kontaktar oss om.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer,',
								'Ev. andra uppgifter vi behöver behandla för att hantera ditt klagomål.',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Behandlingen fortlöper tills ärendet är stängt. Därefter sparar vi dina personuppgifter kopplade till ärendet i 2 år för ha tillgång till historik och vad klagomålet mynnade ut i.',
						],
						[
							'Att underhålla, utveckla, testa och förbättra våra Tjänster och de tekniska plattformar som den tillhandahålls på.',
							['Namn', 'Adress', 'Telefonnummer', 'E-post', 'Personnummer,'],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att tillhandahålla och administrera signeringslösning vid ingående av avtal.',
							['Namn', 'Personnummer', 'Telefonnummer', 'Kontonummer'],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
					]}
				/>

				<h3 id="3-3-personuppgiftsbehandling-i-anslutning-till-fprsakringsformedling">
					3.3 Personuppgiftsbehandling i anslutning till låneförmedling
				</h3>
				<HtmlTable
					head={[
						'Syftet med behandlingen – vad vi gör och varför',
						'Typer av personuppgifter som används för syftet',
						'Laglig grund enligt GDPR för att behandla personuppgifterna',
						'Hur länge vi sparar dina personuppgifter i de olika behandlingarna',
					]}
					body={[
						[
							'Att registrera och administrera din låneansökan i syfte att tillhandahålla Tjänsten till dig inklusive att presentera låneerbjudanden från de långivare vi samarbetar med och att fullgöra vårt avtal med den eventuella långivare du ingår ett låneavtal med.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan.',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst. Om du avbryter eller vi nekar dig lån kan vi komma att behandla dina uppgifter i marknadsföringssyfte enligt avsnitt 3.5.',
						],
						[
							'Förhindra att MyMoneys verksamhet utnyttjas för penningtvätt eller finansiering av terrorism, genom att genomföra kundkännedomsåtgärder, övervaka och granska transaktioner, och genomföra riskbedömningar samt för att skapa riskmodeller. Den här behandlingen utgör profilering och beslut',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan',
							],
							'Det finns lagkrav på oss att vi måste fastställa våra kunders identitet (Artikel 6(1)(c) GDPR). (Lag (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism).',
							'Vi sparar dina uppgifter i minst 5 år och som mest 10 år efter att ansökan har gjorts i enlighet med Lag (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism.',
						],
						[
							'om du innebär en penningtvättsrisk utgör så kallade automatiserade beslut.',
							[
								'UC-information (t ex. årsinkomst, om du har betalningsanmärkningar)',
								'Utdrag på förekomst i EU:s sanktionslistor och PEP-register',
							],
						],
						[
							'Att analysera uppgifter i låneansökan samt uppgifter i kreditupplysningen i syfte att avgöra om du är berättigad till ett lån. Detta innebär en profilering och beslutet om att godkänna eller inte godkänna krediten kan utgöra ett så kallat automatiserat beslut.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan',
								'UC-information (årsinkomst, om du har betalningsanmärkningar).',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att överföra låneansökan till de långivare vi samarbetar med, vars grundkrav för låntagare du uppfyller.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan',
								'UC-information (t.ex. årsinkomst, om du har betalningsanmärkningar)',
								'Utdrag på förekomst i EU:s sanktionslistor och PEP-register.',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att förebygga, upptäcka och motverka bedrägerier och missbruk av Tjänsten.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Ev. andra uppgifter vi behöver.',
							],
							'Behandlingen är baserad på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att behandla personuppgifter för att motverka bedrägeri.',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
					]}
				/>

				<h3 id="3-3-personuppgiftsbehandling-i-anslutning-till-forsakringsformedling">
					3.3 Personuppgiftsbehandling i anslutning till försäkringsförmedling
				</h3>
				<p>
					Här beskriver vi vilka personuppgifter vi behandlar i samband med
					försäkringsförmedling, för vilka ändamål vi behandlar dem och vilket
					lagligt stöd vi har för behandlingen.
				</p>
				<HtmlTable
					head={[
						'Syftet med behandlingen – vad vi gör och varför',
						'Typer av personuppgifter som används för syftet',
						'Laglig grund enligt GDPR för att behandla personuppgifterna',
						'Hur länge vi sparar dina personuppgifter i de olika behandlingarna',
					]}
					body={[
						[
							'Att registrera och administrera din försäkringsansökan i syfte att tillhandahålla Tjänsten till dig i enlighet med vårt avtal.',
							[
								'Namn',
								'Kontaktuppgifter (t.ex. adress, telefonnummer, e-post)',
								'Personnummer',
								'Kontonummer',
								'Andra eventuella uppgifter du lämnar vid registrering av försäkringen',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att överföra försäkringsuppgifter till försäkringsbolaget.',
							[
								'Namn',
								'Kontaktuppgifter (t.ex. adress, telefonnummer, e-post)',
								'Personnummer',
								'Kontonummer',
								'Andra eventuella uppgifter du lämnar vid registrering av försäkringen',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
					]}
				/>

				<h3 id="3-4-personuppgiftsbehandling-i-anslutning-till-kreditgivning-och-kreditadministration">
					3.4 Personuppgiftsbehandling i anslutning till kreditgivning och
					kreditadministration
				</h3>
				<p>
					Här beskriver vi vilka personuppgifter vi behandlar i samband med
					kreditgivning och kreditadministration, för vilka ändamål vi behandlar
					dem och vilket lagligt stöd vi har för behandlingen.
				</p>
				<HtmlTable
					head={[
						'Syftet med behandlingen – vad vi gör och varför',
						'Typer av personuppgifter som används för syftet',
						'Laglig grund enligt GDPR för att behandla personuppgifterna',
						'Hur länge vi sparar dina personuppgifter i de olika behandlingarna',
					]}
					body={[
						[
							'Att registrera och administrera din låneansökan i syfte att tillhandahålla Tjänsten till dig.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan.',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst. Om du avbryter eller vi nekar dig lån kan vi komma att behandla dina uppgifter i marknadsföringssyfte enligt avsnitt 3.5.',
						],
						[
							'Förhindra att MyMoneys verksamhet utnyttjas för penningtvätt eller finansiering av terrorism, genom att genomföra kundkännedomsåtgärder, övervaka och granska transaktioner, och genomföra riskbedömningar samt för att skapa riskmodeller. Den här behandlingen utgör profilering och beslut om du innebär en penningtvättsrisk utgör så kallade automatiserade beslut.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan',
								'UC-information (årsinkomst, om du har betalningsanmärkningar)',
								'Utdrag på förekomst i EU:s sanktionslistor och PEP-register.',
							],
							'Det finns lagkrav på oss att vi måste fastställa våra kunders identitet (Artikel 6(1)(c) GDPR). (Lag (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism).',
							'Vi sparar dina uppgifter i minst 5 år och som mest 10 år efter att ansökan har gjorts i enlighet med Lag (2017:630) om åtgärder mot penningtvätt och finansiering av terrorism.',
						],
						[
							'Att analysera uppgifter i låneansökan samt uppgifter i kreditupplysningen i syfte att avgöra om du är berättigad till ett lån. Detta innebär en profilering och beslutet om att godkänna eller inte godkänna krediten kan utgöra ett så kallat automatiserat beslut.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Information om sysselsättning',
								'Information om boende',
								'Information om hushållets kostnader',
								'Civilstånd',
								'Antal barn',
								'Andra uppgifter du eventuellt har lämnat i ansökan',
								'UC-information (t.ex. årsinkomst, om du har betalningsanmärkningar).',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att utföra skuldindrivningstjänster',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Ekonomiska uppgifter: Lön, Årsinkomst, Uppgifter om.',
							],
							'Behandlingen är baserad på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att kunna driva in och sälja skulder.',
							'När skulden betalats.',
						],
						[
							'Att förebygga, upptäcka och motverka bedrägerier och missbruk av Tjänsten.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Ev. andra uppgifter vi behöver.',
							],
							'Behandlingen är baserad på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att behandla personuppgifter för att motverka bedrägeri.',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Bokföring och redovisning',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'E-post',
								'Personnummer',
								'Kontonummer',
								'Lånebelopp',
							],
							'Följa lag (Artikel 6(1)(c) GDPR). (Bokföringslag (1999:1078))',
							'7 år efter utgången av det år som uppgiften registrerades.',
						],
					]}
				/>

				<h3 id="3-5-personuppgiftsbehandling-i-anslutning-till-marknadsforing">
					3.5 Personuppgiftsbehandling i anslutning till marknadsföring
				</h3>
				<p>
					Här beskriver vi vilka personuppgifter vi behandlar i samband med
					marknadsföring, för vilka ändamål vi behandlar dem och vilket lagligt
					stöd vi har för behandlingen.
				</p>
				<HtmlTable
					head={[
						'Syftet med behandlingen – vad vi gör och varför',
						'Typer av personuppgifter som används för syftet, samt var de kommer ifrån',
						'Laglig grund enligt GDPR för att behandla personuppgifterna',
						'Hur länge vi sparar dina personuppgifter i de olika behandlingarna',
					]}
					body={[
						[
							'Att bestämma vilken marknadsföring vi ska tillhandahålla dig. Om du inte vill att vi ska utföra denna behandling av dina uppgifter kan du meddela oss detta genom att kontakta oss. Vi kommer då att sluta använda dina uppgifter för marknadsföring. Kontaktuppgifter hittar du i avsnitt 7. Behandlingen kan utgöra profilering.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'Personnummer',
								'Information kring din användning av våra tjänster.',
							],
							'Behandlingen baseras på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att avgöra vilken marknadsföring vi ska tillhandahålla dig. Vi säkerställer att den behandling det här innebär är nödvändig för att fullfölja det intresset, och att vårt intresse väger tyngre än din rätt att inte få dina uppgifter behandlade för det här syftet.',
							'Tills du meddelar oss att du inte är intresserad av denna behandling.',
						],
						[
							'Tillhandahålla marknadsföring och erbjudanden till dig om andra tjänster som vi eller bolag i vår koncern erbjuder. Om du inte vill få marknadsföring från oss kan du meddela oss detta genom att kontakta oss. Vi kommer då att sluta behandla dina personuppgifter för att skicka dig marknadsföring. Se kontaktuppgifter i avsnitt 7.',
							[
								'Namn',
								'Adress',
								'Telefonnummer',
								'Personnummer',
								'Information kring din användning av våra tjänster.',
							],
							'Behandlingen baseras på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att avgöra vilken marknadsföring vi ska tillhandahålla dig. Vi säkerställer att den behandling det här innebär är nödvändig för att fullfölja det intresset, och att vårt intresse väger tyngre än din rätt att inte få dina uppgifter behandlade för det här syftet.',
							'Tills du meddelar oss att du inte är intresserad av denna behandling.',
						],
						[
							'Tillhandahålla marknadsföring och erbjudanden till dig om andra tjänster som vi eller bolag i vår koncern erbjuder. Om du inte vill få marknadsföring från oss kan du meddela oss detta genom att kontakta oss. Vi kommer då att sluta behandla dina personuppgifter för att skicka dig marknadsföring. Se kontaktuppgifter i avsnitt 7.',
							['Namn', 'Adress', 'Telefonnummer', 'E-postadress.'],
							'Denna behandling baseras på ditt samtycke (artikel 6.1 a i GDPR).',
							'Antingen när du meddelar oss att du vill ta tillbaka ditt samtycke eller när du låter oss veta att du inte längre är intresserad av denna behandling.',
						],
					]}
				/>

				<h3 id="3-6-personuppgiftsbehandling-i-anslutning-till-besok-pa-vara-digitala-kanaler">
					3.6 Personuppgiftsbehandling i anslutning till besök på våra digitala
					kanaler
				</h3>
				<p>
					Här beskriver vi vilka personuppgifter vi behandlar i anslutning till
					besök på våra digitala kanaler, till exempel vår webbplats, för vilka
					ändamål vi behandlar dem och vilket lagligt stöd vi har för
					behandlingen. Informationen i detta avsnitt samlas delvis in genom
					cookies. Du kan läsa mer om hur vi använder cookies och hur du går
					tillväga för att avböja cookies i vår Cookiepolicy som finns
					tillgänglig på vår webbplats{' '}
					<a href="https://mymoney.fi/evasteet">www.mymoney.fi/evasteet</a>.
				</p>
				<HtmlTable
					head={[
						'Syftet med behandlingen – vad vi gör och varför',
						'Typer av personuppgifter som används för syftet, samt var de kommer ifrån',
						'Laglig grund enligt GDPR för att behandla personuppgifterna',
						'Hur länge vi sparar dina personuppgifter i de olika behandlingarna',
					]}
					body={[
						[
							'Att tillhandahålla dig tjänsten Mina Sidor och de funktioner som finns där.',
							[
								'Namn',
								'Kontaktuppgifter (t.ex. adress, telefonnummer, e-post)',
								'Personnummer',
								'Kontonummer',
								'Enhetsinformation',
								'Information om ditt lån.',
							],
							'Behandlingen är nödvändig för att vi ska kunna fullgöra avtalet med dig (Artikel 6(1)(b)GDPR).',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Analysera och utvärdera hur vår hemsida och dess funktioner används och kan förbättras. Om möjligt anonymiserar vi först uppgifterna, vilket gör att det därefter inte förekommer någon personuppgiftsbehandling. Behandlingen kan utgöra profilering.',
							[
								'Enhetsinformation',
								'Information kring din användning av våra tjänster.',
							],
							'Behandlingen baseras på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att analysera och utvärdera hur vår hemsida och dess funktioner används. Vi säkerställer att den behandling det här innebär är nödvändig för att fullfölja det intresset, och att vårt intresse väger tyngre än din rätt att inte få dina uppgifter behandlade för det här syftet.',
							'Denna behandling pågår så länge du använder vår tjänst.',
						],
						[
							'Att utföra bedrägeriförebyggande bedömningar vid användning av MyMoneys digitala tjänster. Denna process innefattar profilering och automatiserat beslutsfattande. Vi använder automatiserat beslutsfattande i detta syfte för att kunna fastställa om du utgör en bedrägeririsk.',
							[
								'Namn',
								'Kontaktuppgifter (t.ex. adress, telefonnummer, e-post)',
								'Personnummer',
								'Kontonummer',
								'Information om ditt lån',
								'Enhetsinformation',
								'Andra uppgifter om dig som fylls i på tjänsterna.',
							],
							'Behandlingen är baserad på en intresseavvägning (Artikel 6(1)(f) GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett berättigat intresse att behandla personuppgifter för att motverka bedrägeri.',
							'När bedrägeribedömningen utförs.',
						],
					]}
				/>

				<h3 id="3-6-personuppgiftsbehandling-i-anslutning-till-rekrytering">
					3.6 Personuppgiftsbehandling i anslutning till rekrytering
				</h3>
				<p>
					Om du ansöker en av oss, eller av oss anlitad rekryteringspartner,
					utannonserad jobbannons, eller skickar en spontanansökan till oss
					avseende arbete, kommer vi att behandla dina personuppgifter i syfte
					att granska din ansökan till aktuell tjänst och kunna använda din
					ansökan i framtida lediga tjänster vi tror kan passa dig.
					Personuppgifterna vi behandlar är normalt ditt namn, adress,
					telefonnummer, e-post samt uppgifterna du anger i ditt CV, personliga
					brev, betyg och andra intyg och dokument du väljer att skicka till
					oss. Behandlingen är baserad på en intresseavvägning (Artikel 6(1)(f)
					GDPR). Vid intresseavvägningen har MyMoney bedömt att vi har ett
					berättigat intresse att behandla personuppgifter för
					rekryteringsändamål. Vi sparar dina uppgifter i 1 år från att de
					skickades in, såvida du inte dessförinnan ber oss att radera dem.
				</p>

				<h3 id="3.7 Särskilt om automatiskt beslutsfattande">
					3.7 Särskilt om automatiskt beslutsfattande
				</h3>
				<p>
					Automatiserat beslutsfattande avser ett beslut som enbart fattas på
					basis av automatiserad behandling av dina personuppgifter. Vi och de
					långivare vi samarbetar med använder automatiserat beslutfattande när
					du lämnar in en låneansökan. Det betyder att den information som du
					har lämnat och den information som vi hämtar genom en kreditupplysning
					om dig och eventuell medsökande matchas automatiskt med de grundkrav
					på låntagare som vi och våra anslutna långivare tillämpar för att
					bevilja ett lån, till exempel inkomst, anställningsform, sökt
					lånebelopp och liknande uppgifter. Om du inte uppfyller de grundkrav
					som en specifik långivare ställer upp kommer din ansökan automatiskt
					att sorteras bort och inte tillhandahållas långivaren.
				</p>
				<p>
					I vissa fall har du rätt att begära en manuell beslutsprocess.
					Kontakta oss i sådant fall på kontaktuppgifterna nedan. Du kan även
					vända dig till respektive långivare för mer information om hur de
					använder sig av automatiserat beslutsfattande och om du har frågor om
					respektive långivares personuppgiftsbehandling.
				</p>
				<p>
					Vår användning av automatiserat beslutsfattande syftar till att kunna
					tillhandahålla en rättvis och korrekt låneförmedlingstjänst och är
					nödvändigt för att vi ska kunna fullgöra det avtal som vi har ingått
					med dig. Om du har en invändning mot ett automatiskt beslut som tagits
					av oss ber vi dig att kontakta oss på{' '}
					<a href="mailto:privacy@mymoney.se">privacy@mymoney.se</a>.
				</p>

				<h3 id="3-8-sarskilt-om-profilering">3.8 Särskilt om profilering</h3>
				<p>
					Profilering avser en automatisk behandling av befintliga eller
					potentiella kunders personuppgifter som används för att bedöma vissa
					personliga egenskaper hos dem, för att analysera eller förutsäga
					exempelvis dennes ekonomiska situation, personliga preferenser,
					intressen och vistelseort. Vi använder oss av profilering för till
					exempel marknads- och kundanalyser, systemutveckling, marknadsföring,
					vid automatiserade beslut (se ovan) och vid transaktionsmonitorering
					för att motverka bedrägerier. Detaljer kring syfte, personuppgifter
					m.m. för MyMoneys profilering beskrivs närmare under respektive
					behandling i tabellerna ovan. Profilering sker i vissa fall genom
					cookies på vår hemsida. Du kan läsa mer om hur vi använder cookies och
					hur du går tillväga för att avböja cookies i vår Cookiepolicy som
					finns tillgänglig på vår webbplats{' '}
					<a href="https://mymoney.fi/evasteet">www.mymoney.fi/evasteet</a>.
				</p>

				<h3 id="3-8-sarskilt-om-samtycke">3.8 Särskilt om samtycke</h3>
				<p>
					När vi behandlar dina personuppgifter med stöd av samtycke får du
					uttryckligen lämna ett sådant samtycke till att personuppgifterna får
					behandlas. Exempel på när vi använder samtycke för behandling av
					personuppgifter är vid direktmarknadsföring.
				</p>
				<p>
					Du har rätt att när som helst återkalla ditt samtycke. Vi har då
					fortsättningsvis ingen rätt att behandla uppgifterna med stöd av
					samtycket. Om du vill återkalla ditt samtycke hittar du våra
					kontaktuppgifter nedan.
				</p>

				<h2 id="4-vilka-kan-vi-komma-att-dela-din-information-med">
					4. Vilka kan vi komma att dela din information med?
				</h2>
				<p>
					Vi vidtar alla rimliga kontraktuella, legala, tekniska och
					organisatoriska åtgärder för att säkerställa att dina personuppgifter
					behandlas på ett säkert sätt och med en adekvat skyddsnivå när de
					överförs till eller delas med utvalda tredje parter. Sådana tredje
					parter kommer att vara:
				</p>
				<p>
					<strong>Leverantörer.</strong> Vissa av våra leverantörer som
					tillhandahåller tjänster där dina personuppgifter är nödvändiga att
					behandlas, till exempel leverantörer av e-signaturer och e-
					identifiering, leverantörer för att avisera dig digitalt eller
					postalt, samt leverantörer av IT-tjänster som är nödvändiga för oss
					att tillhandahålla Tjänsten.
				</p>
				<p>
					<strong>Kreditupplysningsföretag och liknande leverantörer.</strong>{' '}
					Dina personuppgifter kan komma att delas med kreditupplysningsföretag
					för att bedöma din kreditvärdighet när du använder vår
					låneförmedlingstjänst. Personuppgifterna kan även komma att delas med
					leverantörer av tjänster för identitetsuppslag och förebyggande av
					bedrägerier för att bekräfta din identitet och adress samt skydda dig
					från bedrägeri.
				</p>
				<p>
					<strong>Myndigheter.</strong> Vi kan komma att lämna nödvändig
					information till myndigheter som Polisen, Finansinspektionen eller
					andra myndigheter om vi enligt lag är skyldiga att göra det. Vi är
					till exempel enligt lag skyldiga att lämna information för åtgärder
					mot penningtvätt och finansiering av terrorism.
				</p>
				<p>
					<strong>Långivare.</strong> Vid en lånejämförelse skickar vi din
					ansökan vidare till de långivare vi samarbetar med och vars grundkrav
					överensstämmer med din ansökan. De långivare som tar emot din ansökan
					är personuppgiftsansvariga för sin egen behandling av dina
					personuppgifter.
				</p>
				<p>
					<strong>Återförsäljare.</strong> Om du besöker en av våra
					återförsäljare för att lämna en låneansökan behandlar aktuell
					återförsäljare dina personuppgifter som personuppgiftsbiträde åt
					MyMoney i syfte att tillhandahålla Tjänsten till dig.
				</p>
				<p>
					<strong>Koncernbolag.</strong> Vi kan komma att dela uppgifter med
					våra koncernbolag i syfte att effektivisera interna processer och
					sammanställa gemensam statistik samt för att marknadsföra andra
					finansiella- och försäkringsprodukter.
				</p>
				<p>
					<strong>Avyttring.</strong> För det fall att vi säljer eller köper
					verksamheter kan vi komma att lämna dina personuppgifter till en
					potentiell säljare eller köpare av sådan verksamhet. Om vi eller en
					väsentlig del av vår verksamhet förvärvas av en tredje part kan
					personuppgifter om våra kunder komma att delas. Innan sådan delning
					görs kommer vi se till att lämpligt sekretessåtagande finns på plats.
				</p>

				<h2 id="5-var-behandlar-vi-dina-personuppgifter">
					5. Var behandlar vi dina personuppgifter?
				</h2>
				<p>
					Vi behandlar främst dina personuppgifter inom EU/EES. I undantagsfall
					kan personuppgifter komma att överföras till, och behandlas i, land
					utanför EU/EES, s.k. tredjeland. Bolag som hanterar personuppgifter
					för vår räkning undertecknar alltid avtal med oss för att säkerställa
					en hög skyddsnivå för dina personuppgifter. I relation till
					samarbetspartners utanför EU/EES vidtas särskilda skyddsåtgärder, till
					exempel genom att vi ingår avtal som inkluderar EU- kommissionens
					standardiserade modellklausuler för dataöverföring och som syftar till
					att säkerställa en skyddsnivå för dina personuppgifter som är
					likvärdig med det skydd som erbjuds inom EU/EES. En överföring till
					ett tredjeland kan även baseras på att EU-kommissionen har fattat
					beslut om att landet har en adekvat skyddsnivå för personuppgifter (så
					kallat adekvansbeslut).
				</p>
				<p>
					Information om vilka länder som EU-kommissionen har fattat
					adekvansbeslut för finns här:
				</p>
				<p>
					<a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_sv">
						Adequacy decisions | Europeiska kommissionen (europa.eu)
					</a>
				</p>
				<p>EU-kommissionens standardavtalsklausuler finns tillgängliga här:</p>
				<p></p>
				<p>
					<a href="https://commission.europa.eu/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_sv">
						Standard Contractual Clauses (SCC) | Europeiska kommissionen
						(europa.eu)
					</a>
				</p>
				<p>
					Du kan kontakta{' '}
					<a href={`mailto:privacy@mymoney.se`}>privacy@mymoney.se</a> för att
					få mer information om överföringar till tredjeland.
				</p>

				<h2 id="6-hur-lange-sparar-vi-dina-personuppgifter">
					6. Hur länge sparar vi dina personuppgifter?
				</h2>
				<ul>
					<li>
						<p>
							Dina personuppgifter sparas endast så länge de är nödvändiga med
							hänsyn till ändamålet för behandlingen eller då det krävs att vi
							lagrar dem i enlighet med gällande lagstiftning.
						</p>
					</li>
					<li>
						<p>
							Personuppgifter som krävs för att utföra Tjänsten kommer att
							sparas så länge de är nödvändiga för att fullgöra vårt avtal med
							dig.
						</p>
					</li>
					<li>
						<p>
							Personuppgifter vi enligt lag är skyldiga att spara för att
							uppfylla krav inom penningtvättsområdet sparas som minst 5 år men
							som mest i 10 år från att avtalsförhållandet upphört.
						</p>
					</li>
					<li>
						<p>
							Personuppgifter som är nödvändiga för bokföringsändamål sparas i 7
							år efter det kalenderår då räkenskapsåret avslutades.
						</p>
					</li>
					<li>
						<p>
							Personuppgifter som krävs för att vi ska fullgöra vårt avtal med
							en specifik långivare kommer att lagras så länge det är nödvändigt
							för att uppfylla vårt avtal med långivaren, högst 10 år från att
							avtalsförhållandet upphört.
						</p>
					</li>
					<li>
						<p>
							Personuppgifter som vi använder för marknadsföringsändamål med
							stöd av samtycke sparas under den tid ett giltigt samtycke finns.
						</p>
					</li>
				</ul>

				<h2 id="7-vad-har-du-for-rattigheter">
					7. Vad har du för rättigheter?
				</h2>
				<p>
					<strong>Rätt att få tillgång till dina uppgifter</strong>
				</p>
				<p>
					Du kan begära en kopia av dina uppgifter om du vill veta vilken
					information vi har om dig, ett s.k. registerutdrag.
				</p>
				<p>
					<strong>Rätt till rättelse</strong>
				</p>
				<p>
					Du har rätt att få felaktiga personuppgifter rättade eller komplettera
					ofullständiga personuppgifter om dig.
				</p>
				<p>
					<strong>Rätt att bli raderad</strong>
				</p>
				<p>
					Du har rätt att begära radering av vissa personuppgifter. Observera
					att vi i vissa fall inte kan radera dina uppgifter, till exempel om de
					krävs för att vi ska uppfylla en rättslig förpliktelse eller för att
					kunna fastställa, göra gällande eller försvara rättsliga anspråk.
				</p>
				<p>
					<strong>Rätt till begränsning av behandling</strong>
				</p>
				<p>
					Du har rätt att begära att behandlingen av dina personuppgifter
					begränsas, till exempel om du motsätter dig uppgifters riktighet.
				</p>
				<p>
					<strong>Rätt att göra invändningar</strong>
				</p>
				<p>
					När vi anser att vi har ett berättigat intresse för att behandla dina
					personuppgifter kan du när som helst göra en invändning mot
					behandlingen. Om du väljer att göra en invändning får vi inte längre
					behandla dina personuppgifter för det ändamålet om vi inte kan påvisa
					berättigat intresse för behandlingen. Sådant berättigat intresse måste
					väga tyngre än ditt intresse av att dina personuppgifter inte
					behandlas av integritetsskäl. Du kan också alltid invända mot
					behandling som vi utför för direktmarknadsföringsändamål.
				</p>
				<p>
					<strong>Rätt till dataportabilitet</strong>
				</p>
				<p>
					De personuppgifter som du själv har lämnat till oss har du rätt att ta
					emot och/eller kräva överföras till en annan personuppgiftsansvarig.
					Personuppgifterna måste vara i ett strukturerat, vanligt använt och
					maskinläsbart format. En förutsättning för dataportabilitet är att
					överföringen är tekniskt möjlig och kan ske automatiserat.
				</p>
				<p>
					<strong>Rätt att lämna in ett klagomål</strong>
				</p>
				<p>
					Om du har synpunkter eller klagomål rörande vår behandling av dina
					personuppgifter eller vill utöva någon av dina rättigheter är du
					välkommen att kontakta oss på{' '}
					<a href={`mailto:privacy@mymoney.se`}>privacy@mymoney.se</a>.
				</p>
				<p>
					Om vi mot all förmodan inte skulle lyckas hitta en lösning tillsammans
					kan du vända dig till Integritetsskyddsmyndigheten som är
					tillsynsmyndighet för behandling av personuppgifter:
				</p>
				<p>Adress: Lintulahdenkuja 4, 00530 Helsinki, Suomi</p>
				<p>
					E-post: <a href="mailto:tietosuoja@om.fi">tietosuoja@om.fi</a>
				</p>
				<p>Telefon: 029 566 6700</p>
				<p>
					Webbplats: <a href="https://tietosuoja.fi">www.tietosuoja.fi</a>
				</p>
				<h2 id="8-andringar-i-dataskyddspolicyn">
					8. Ändringar i Dataskyddspolicyn
				</h2>
				<p>
					Vi förbehåller oss rätten att ändra och uppdatera vår
					Dataskyddspolicy. Den senaste versionen finns alltid på vår webbplats{' '}
					<a href="https://www.mymoney.se">www.mymoney.se</a>. Vid uppdateringar
					som är av avgörande betydelse för vår hantering av dina
					personuppgifter kommer du att få information om ändringarna på vår
					webbplats i god tid innan uppdateringarna börjar gälla. Om du har
					synpunkter på vår hantering av personuppgifter till följd av
					uppdateringarna är du välkommen att kontakta oss på{' '}
					<a href={`mailto:privacy@mymoney.se`}>privacy@mymoney.se</a>.
				</p>
			</>
		)
	},
}

export default PrivacyPolicy
