import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '../components/Container'

const employees = [
	{
		name: 'Sami Päiväniemi',
		role: 'contact_sami_role',
		email: 'sami.paivaniemi@mymoney.fi',
		phoneNumbers: ['09 31549402', '+358 400 767 645'],
	},
	{
		name: 'Mikko Malinen',
		role: 'contact_mikko_role',
		email: 'mikko.malinen@mymoney.fi',
		phoneNumbers: ['09 31549402', '+358 409 218 672'],
	},
	{
		name: 'Karoliina Rahman',
		role: 'contact_karoliina_role',
		email: 'karoliina.rahman@mymoney.fi',
		phoneNumbers: ['09 31549402'],
	},
	{
		name: 'Oliver Tomasino',
		role: 'contact_oliver_role',
		email: 'oliver.tomasino@mymoney.fi',
		phoneNumbers: ['+358 457 200 599'],
	},
]

const Contact = () => {
	const { t } = useTranslation()
	return (
		<Container>
			<div className="information-page align-center">
				<div className="contact-header">
					<h1 className="line-center">{t('contact_heading')}</h1>
					<p className="text-align-center">
						<i>{t('contact_heading_text')}</i>
					</p>
				</div>
				<div className="contact-container">
					<div>
						<h4>{t('contact_for_customers')}</h4>
						<p>
							<Link to="tel:0931549402">09 31549402</Link>
						</p>
						<p>
							<Link to="mailto:info@mymoney.fi">info@mymoney.fi</Link>
						</p>
					</div>
					<div>
						<h4>{t('contact_for_partners')}</h4>
						<p>
							<Link to="tel:0931549402">09 31549402</Link>
						</p>
						<p>
							<Link to="mailto:kumppanit@mymoney.fi">kumppanit@mymoney.fi</Link>
						</p>
					</div>
					<div>
						<h4>{t('contact_postal_address')}</h4>
						<p>{t('business_id')} 3303458-1</p>
						<p>Södergatan 24, Box 4305, 211 34, MALMÖ, RUOTSI</p>
					</div>
				</div>
				<div className="employees">
					{employees.map(employee => (
						<div key={employee.email}>
							<p className="name">{employee.name}</p>
							<p className="title">{t(employee.role)}</p>
							<p>
								<Link to={`mailto:${employee.email}`}>{employee.email}</Link>
							</p>
							{employee.phoneNumbers.map(phoneNumber => (
								<p key={phoneNumber}>
									<Link to={`tel:${phoneNumber}`}>{phoneNumber}</Link>
								</p>
							))}
						</div>
					))}
				</div>
			</div>
		</Container>
	)
}

export default Contact
