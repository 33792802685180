import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '../../components/Container'

const TermsAndConditions = () => {
	const { t, i18n } = useTranslation()

	const Body = body[i18n.language]

	return (
		<Container className="py-20">
			<div className="information-page">
				<h1 className="line-center text-align-center">
					{t('terms_and_conditions_heading')}
				</h1>
				<div className="prose mx-auto">
					<Body />
				</div>
			</div>
		</Container>
	)
}

const body = {
	fi: () => {
		return (
			<>
				<h2>GDPR</h2>
				<p>
					25. Toukokuuta 2018, tuli voimaan uusi EU:n laajuinen sääntelykehys,
					yleinen tietosuoja-asetus General Data Protection Regulation, (GDPR).
					Tarkoituksena on suojella henkilöitä sääntelemällä sitä, miten
					henkilötietoja voidaan käsitellä.
				</p>
				<h2>Henkilötietojen vastuullinen</h2>
				<p>
					My Money OY, organisaationumerolla 556702-1679, on antamasi tietojen
					vastuullinen.
				</p>
				<h2>Henkilötietojen käsittely</h2>
				<p>
					Henkilötietosi rekisteröidään kuin teet lainahakemuksen ja säilytämme
					myös sinun rahoitusehdotuksesi. Henkilötietosi käsittelee MyMoney sekä
					yhteystyökumppanimme, joiden kanssa olemme tehneet sopimuksen koskien
					hallinnoimisen ja täyttämisen. Yhteistyökumppanimme käyttävät näitä
					tietoja pystyäkseen rekisteröimään sinut meidän järjestelmäämme sekä
					lähettämään laskujasi. Henkilötietosi voidaan myös lähettää meidän
					yhteistyökumppaneillemme tarvittaessa.
				</p>
				<p>
					Tietoja käsitellään myös, jotta MyMoney täyttää lakisääteiset
					velvoitteensa. Voimme käyttää henkilötietoja myös markkina-analyysien,
					mainoksiin sekä riskienhallintaan.
				</p>
				<p>
					Voit lukea meistä lisää sekä myös sinun oikeudestasi ositteessa{' '}
					<Link to="/tietosuojaseloste">
						https://mymoney.fi/tietosuojaseloste
					</Link>
					, täältä löydät MyMoney:n kokonaisen tietosuojaselosteen.
				</p>
				<h2>Valituksen käsittely</h2>
				<p>
					MyMoney:n motto on tehdä asiakkaamme tyytyväiseksi ja otamme, saaneet
					valitukset erittäin vakavasti. Jos olisit tyytymätön palvelustamme,
					pyydämme sinua kääntymään ensikäteisesti neuvonantajaan, joka auttoi
					sinua.
				</p>
				<p>
					Jos ette onnistu löytämään yhteistä ratkaisua, pyydämme sinua
					kääntymään meidän valituksen käsittely-osastoon sähköpostitse{' '}
					<Link to="mailto:info@mymoney.fi">info@mymoney.fi</Link> tai postitse
					osoitteeseen MyMoney Oy, Södergatan 24, Box 4305, 211 34, MALMÖ,
					RUOTSI.
				</p>
				<h2>Luottotiedot</h2>
				<p>
					MyMoney voi hankia luottotietoja sinusta, joko itse tai useamman
					yhteistyökumppanin kautta. Luottotiedot voidaan hakea
					Asiakastiedostosta.
				</p>
			</>
		)
	},
	sv: () => {
		return (
			<>
				<h2>GDPR</h2>
				<p>
					Den 25 maj 2018 började ett nytt EU-gemensamt regelverk, GDPR, General
					Data Protection Regulation, att gälla. Syftet med GDPR är att skydda
					fysiska personer genom att reglera hur personuppgifter får behandlas.
				</p>
				<h2>Personuppgiftsansvarig</h2>
				<p>
					MyMoney AB med organisationsnummer 556702-1679 är
					personuppgiftsansvarig för de uppgifter som du lämnar.
				</p>
				<h2>Hantering av personuppgifter</h2>
				<p>
					Personuppgifter registreras i samband med låneansökan och vid lagrande
					av finansieringsförslag. Personuppgifterna behandlas av MyMoney och
					andra företag som MyMoney samarbetar med för hantering och fullgörelse
					av ingångna avtal. Denna information ligger bl.a. till grund för att
					våra partners skall kunna registrera och skicka betalningsavier och
					fakturor. Vidare kan personuppgifter komma att lämnas ut för
					behandling av MyMoney’s samarbetspartners.
				</p>
				<p>
					Behandling av uppgifter sker också för att MyMoney skall kunna
					fullgöra sina förpliktelser enligt lag. Personuppgifterna kan även
					ligga till grund för marknadsanalyser, marknadsföring och
					riskhantering.
				</p>
				<p>
					Ytterligare information samt dina rättigheter kan du läsa mer om på{' '}
					<Link to="/tietosuojaseloste">
						https://mymoney.fi/tietosuojaseloste
					</Link>
					, där du finner MyMoney’s fullständiga dataskyddspolicy.
				</p>
				<h2>Klagomålshantering</h2>
				<p>
					Vi på MyMoney strävar alltid efter att göra våra kunder nöjda och tar
					klagomål på största allvar. Skulle du vara missnöjd med hanteringen av
					ditt ärende vänder du dig i första hand till den rådgivare som har
					handlagt ärendet.
				</p>
				<p>
					Lyckas ni inte komma till någon lösning ber vi dig att vända dig till
					vår klagomålsansvarige per e-post{' '}
					<Link to="mailto:info@mymoney.fi">info@mymoney.fi</Link> eller under
					adressen MyMoney AB, Klagomålsansvarig, Box 4305, 203 14 Malmö.
				</p>
				<h2>Kreditupplysning</h2>
				<p>
					MyMoney kan komma att inhämta kreditupplysning på dig, antingen själva
					eller via en eller flera partners. Kreditupplysning kan inhämtas via
					Syna AB och UC AB.
				</p>
			</>
		)
	},
}

export default TermsAndConditions
