import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '../../components/Container'

const ComplaintHandling = () => {
	const { t } = useTranslation()
	const { i18n } = useTranslation()
	const Body = body[i18n.language]
	return (
		<Container>
			<div className="information-page align-center polygon-footer-background">
				<div className="py-20">
					<h1 className="line-center complaint-handling-header">
						{t('complaint_handling_heading')}
					</h1>
				</div>
				<div className="prose mx-auto">
					<Body />
				</div>
			</div>
			<div className="py-20" />
		</Container>
	)
}

const body = {
	fi: () => {
		return (
			<>
				<p>
					Haluamme tarjota asiakkaillemme hyvää palvelua ja kehittää
					toimintaamme asiakkaiden tarpeiden mukaisesti. Jos kuitenkin olet
					tyytymätön palveluumme, ota yhteyttä asiakaspalveluumme numeroon{' '}
					<a href="tel:0931549402">09 3154 9402</a>, niin voimme auttaa sinua
					eteenpäin.
				</p>
				<p>
					Voit myös ottaa meihin yhteyttä sähköpostitse osoitteeseen{' '}
					<Link to="mailto:fraud@mymoney.fi">fraud@mymoney.fi</Link>, niin
					palaamme asiaan takaisin sinulle.
				</p>
			</>
		)
	},
	sv: () => {
		return (
			<>
				<p>
					Vi vill ge god service till våra kunder och utveckla vår verksamhet i
					linje med deras behov. Men om du är missnöjd med vår service, vänligen
					kontakta vårt kundtjänstteam på{' '}
					<a href="tel:0931549402">09 3154 9402</a> så hjälper vi dig vidare.
				</p>
				<p>
					Du kan också kontakta oss via e-post på{' '}
					<Link to="mailto:fraud@mymoney.fi">fraud@mymoney.fi</Link> så
					återkommer vi till dig.
				</p>
			</>
		)
	},
}

export default ComplaintHandling
