const RocketIcon = () => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			x="0px"
			y="0px"
			height="30px"
			viewBox="0 0 92 92"
			enableBackground="new 0 0 92 92"
			fill="currentColor"
		>
			<path
				id="XMLID_2180_"
				d="M91.7,3.9c-0.2-2-1.7-3.5-3.7-3.7c-25.3-2-43.4,10.3-54.7,25.7c-6.2-2-12.9-1.3-19,2.1
	c-5.9,3.3-10.8,8.9-14,15.7c-0.7,1.6-0.4,3.4,0.9,4.6c1.2,1.2,3.1,1.4,4.6,0.6c6.4-3.4,13.6-2.7,17.9,1.6l17.6,17.6
	c0,0,0.1,0.1,0.1,0.1c4.3,4.3,5,11.5,1.6,17.9c-0.8,1.5-0.5,3.4,0.6,4.6c0.8,0.8,1.8,1.2,2.9,1.2c0.6,0,1.2-0.1,1.7-0.4
	c6.8-3.2,12.4-8.2,15.7-14c3.5-6.1,4.2-12.8,2.1-19.1c4.5-3.3,8.6-6.9,12-10.9C88.6,35.4,93.2,20.7,91.7,3.9z M13.4,38.8
	c4.3-4.2,9.7-6.8,15.4-5.8c-1.6,2.8-2.9,5.6-4.1,8.4C21.3,39.5,17.4,38.6,13.4,38.8z M53.3,78.6c0.1-4.1-0.8-8-2.6-11.4
	c2.9-1.2,5.7-2.6,8.4-4.1C60.1,68.8,57.6,74.3,53.3,78.6z M45.3,60.7L31.2,46.6C38.3,27.7,55.9,7.5,84,8
	C84.9,41.5,57.3,56,45.3,60.7z M71.2,20.8c1.2,1.2,1.9,2.9,1.9,4.6s-0.7,3.4-1.9,4.6s-2.9,1.9-4.6,1.9S63.2,31.3,62,30
	s-1.9-2.9-1.9-4.6s0.7-3.4,1.9-4.6c1.2-1.2,2.9-1.9,4.6-1.9S70,19.6,71.2,20.8z M14.9,62.4C10.3,67,10.1,76.2,10.1,78
	c0,2.2,1.8,4,4,4c0,0,0.1,0,0.1,0c2.1,0,10.9-0.3,15.4-4.8c2.8-2.8,3.4-5.7,3.4-7.6c0-2.7-1.1-5.3-3.2-7.4
	C24.8,57.1,18.5,58.7,14.9,62.4z M24,71.5c-1,1-3.2,1.7-5.5,2c0.4-2.3,1.1-4.5,2-5.4c0.7-0.7,1.3-1.1,2-1.1c0.5,0,1.1,0.3,1.7,0.9
	c0.6,0.6,0.9,1.2,0.9,1.7C25,70.2,24.5,71,24,71.5z"
			/>
		</svg>
	)
}

export default RocketIcon
