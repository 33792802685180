import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Container } from '../../components/Container'
import { useCookieConsent } from '../../utils/CookieConsentContext'

const browsers = [
	{
		name: 'Internet Explorer',
		href: 'https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies',
	},
	{
		name: 'Microsoft Edge',
		href: 'https://support.microsoft.com/sv-se/help/10607/microsoft-edge-view-delete-browser-history',
	},
	{
		name: 'Mozilla Firefox',
		href: 'http://support.mozilla.com/en-US/kb/Cookies',
	},
	{
		name: 'Google Chrome',
		href: 'http://www.google.com/support/chrome/bin/answer.py?hl=en&answer=95647',
	},
	{
		name: 'Safari',
		href: 'https://support.apple.com/sv-se/guide/safari/sfri11471/mac',
	},
	{
		name: 'Opera',
		href: 'http://www.opera.com/help/tutorials/security/privacy/',
	},
]

const Cookies = () => {
	const { t, i18n } = useTranslation()

	const Body = body[i18n.language]

	return (
		<Container className="py-20">
			<div className="information-page prose mx-auto">
				<h1 className="line-left">{t('cookies_heading')}</h1>
				<Body />
			</div>
		</Container>
	)
}

const body = {
	fi: () => {
		const { showConsentDialog } = useCookieConsent()
		return (
			<>
				<h2>Miten Evästeet toimivat?</h2>
				<p>
					Evästeet ovat pieniä tekstitiedostoja, jotka tallentuvat sinun
					tietokoneeseesi tai toiseen laitteeseen selaimeen kuin käytät
					verkkosivua. Evästeet mahdollistavat verkkosivuston omistajalle esim.
					seuramaan verkkopalvelumme käyttöä sekä tunnistamaan verkkosivun
					toistuvia vierailijoita.
				</p>

				<h2>Näin käytämme Evästeitä</h2>
				<p>
					Käytämme evästeitä kerätäksemme tietoa siitä, kuinka asiakkaamme
					käyttävät sivuamme. Näin voimme parantaa sivuamme ja tehdä tämän
					asiakkaallemme hyödyllisemmäksi. Käytämme myös evästeitä tarjotaksemme
					kiinnostavimpiä mainoksia asiakkaallemme. Mainokset ja analyysit
					verkkosivun seurannasta voi tapahtua meidän yhteistyökumppaneiden ja
					palveluntarjoajien kanssa esim. Google.
				</p>

				<h2>Poista evästesuostumukset</h2>
				<p>
					Voit muuttaa tai peruuttaa suostumuksesi valinnaisten evästeiden
					käytöstä milloin tahansa napsauttamalla alla olevaa painiketta.
				</p>
				<div className="button--primary" onClick={showConsentDialog}>
					Hallitse evästeasetukset
				</div>

				<h2>Käytämme seuraavat Evästeet</h2>
				<h3>Valinnaiset</h3>
				<p>
					Nämä evästeet ovat tarpeellisia sivuston toiminnan kannalta sekä että
					palvelumme toimivat parhaimmalla tavalla.
				</p>

				<h3>Analyysi</h3>
				<p>
					Kerää tietoja verkkosivun käyttäjistä, tunnistamatta yksittäisiä
					kävijöitä henkilökohtaisesti Googlessa. Tämä mahdollistaa
					vierailijoiden erottamisen toisistaan.
				</p>

				<h3>Markkinointi</h3>
				<p>
					Mahdollistaa käyttäjille verkkosivustomme sisällön jakamiseen
					sosiaalisessa mediassa. Tämän avulla voimme myös näyttää kävijöillemme
					sopivia mainoksia.
				</p>

				<h3>Kuinka voit hallita evästeitä?</h3>
				<p>
					Useimmat verkkoselaimet mahdollistavat asetuksien hallinnan evästeille
					sekä mahdollistaa myös poistamisen sekä estämisen. Huomioi, että
					tarvitset vaihtaa asetuksia jokaisessa eri selaimessa sekä eri
					laitteissa. Käyttöohjeiden muutoksen tekeminen vaihtelee eri
					selaimella. Napsauta yhtä alla olevista linkeistä, niin pääset suoraan
					kyseisen selaimen ohjeisiin.
				</p>
				<ul>
					{browsers.map(item => (
						<li key={item.name}>
							<Link to={item.href}>{item.name}</Link>
						</li>
					))}
				</ul>
			</>
		)
	},
	sv: () => {
		const { showConsentDialog } = useCookieConsent()
		return (
			<>
				<h2>Vad är cookies?</h2>
				<p>
					En cookie är en liten textfil som sparas på din dator eller annan
					enhet när du använder en webbplats. Cookies gör det bl.a. möjligt för
					webbplatsinnehavare att se hur webbplatsen används och att identifiera
					en återkommande besökare.
				</p>
				<h2>Så här använder vi Cookies</h2>
				<p>
					Vi använder cookies för att spåra och analysera användningen av vår
					webbplats. Det gör det möjligt för oss att kontinuerligt utveckla och
					förbättra upplevelsen för våra besökare. Vi använder även cookies för
					att tillhandahålla relevanta annonser som kan vara av intresse för
					våra besökare. Annonsering, spårning och analys kan ske tillsammans
					med samarbetspartners och tjänsteleverantörer som t.ex. Google.
				</p>
				<h2>Återkalla samtycke</h2>
				<p>
					Du kan när som helst ändra eller återkalla ditt samtycke till att
					valfria cookies används genom att klicka på knappen nedan.
				</p>
				<div className="button--primary" onClick={showConsentDialog}>
					Hantera cookies
				</div>
				<h2>Dessa typer av Cookies använder vi oss av</h2>
				<h4>Nödvändiga</h4>
				<p>
					Används för grundläggande webbplatsfunktionalitet och för att våra
					tjänster skall fungerar på bästa sätt.
				</p>
				<h4>Analys</h4>
				<p>
					Samlar in information om webbplatsanvändningsstatistik utan att
					personligen identifiera enskilda besökare på Google. Gör det möjligt
					att särskilja en besökare från en annan.
				</p>
				<h4>Marknadsföring</h4>
				<p>
					Gör det möjligt för användare att dela innehåll från vår webbplats i
					sina sociala medier. Skapar också förutsättningar för oss att visa
					annonser som är relevanta för våra besökare.
				</p>
				<h4>Hur kan du hantera cookies?</h4>
				<p>
					De flesta webbläsare tillåter dig att hantera inställningar för
					Cookies och gör det möjligt att radera och blockera cookies. Observera
					att du måste ändra inställningar på varje webbläsare och enhet som du
					använder. Anvisningar för hur man ändrar inställningarna och cookies
					skiljer sig mellan olika webbläsare. Klicka på någon av länkarna nedan
					så kommer du direkt till anvisningarna för just den webbläsaren:
				</p>
				<ul>
					{browsers.map(item => (
						<li key={item.name}>
							<Link to={item.href}>{item.name}</Link>
						</li>
					))}
				</ul>
			</>
		)
	},
}

export default Cookies
