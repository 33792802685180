const GlobeIcon = () => {
	return (
		<svg
			version="1.1"
			width="36px"
			height="36px"
			viewBox="0 0 100 100"
			enableBackground="new 0 0 100 100"
		>
			<g id="World">
				<circle
					fill="none"
					stroke="#000000"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					cx="50.188"
					cy="50"
					r="23.188"
				/>

				<ellipse
					fill="none"
					stroke="#000000"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					cx="50.188"
					cy="50"
					rx="23.229"
					ry="12.25"
				/>

				<ellipse
					fill="none"
					stroke="#000000"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					cx="50.188"
					cy="50"
					rx="12.438"
					ry="23.229"
				/>

				<line
					fill="none"
					stroke="#000000"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="27"
					y1="50"
					x2="73"
					y2="50"
				/>

				<line
					fill="none"
					stroke="#000000"
					strokeWidth="4"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeMiterlimit="10"
					x1="50"
					y1="27"
					x2="50"
					y2="73"
				/>
			</g>
		</svg>
	)
}

export default GlobeIcon
